import { observer } from "mobx-react";
import * as queryString from "query-string";
import * as React from "react";
import { useLocation } from "react-router";
import { t } from "../../../../i18n/util";
import { API, isUnauthorizedError } from "../../../../network/API";
import { doctorStore } from "../../../../stores/DoctorStore";
import { generalStore } from "../../../../stores/GeneralStore";
import { IPatient } from "../../../../types";
import { StandardContainer } from "../../../containers/StandardContainer";
import { ReleaseReportConfirmDialog } from "../../../dialogs/ReleaseReportConfirmDialog";
import { history } from "../../../routers/history";
import { Routes } from "../../../routers/Routes";
import { CustomPagination } from "../../../ui/CustomPagination";
import { Searchbar } from "../../../ui/Searchbar";
import { UserNavigation } from "../../../ui/UserNavigation";
import { fetchFile } from "../../../util/fetchFile";
import { useDebouncedState } from "../../../util/useDebouncedState";
import { useTableSort } from "../../../util/useTableSort";
import { EmptyHint } from "./EmptyHint";
import { PatientsTable } from "./PatientsTable";

const ROWS_PER_PAGE = 10;

export const DoctorSite = observer(() => {
    const location = useLocation();
    const searchQuery = queryString.parse(location.search).search;
    const [debouncedSearchKeyword, searchKeyword, setSearchKeyword] = useDebouncedState(
        typeof searchQuery === "string" ? searchQuery : doctorStore.patientTable.debouncedSearchKeyword,
        500,
    );
    const [searchTotalRows, setSearchTotalRows] = React.useState<number | null>(null);
    const [rows, setRows] = React.useState<IPatient[]>([]);
    const [totalRows, setTotalRows] = React.useState<number | null>(null);
    const [currentPage, setCurrentPage] = React.useState(doctorStore.patientTable.currentPage);
    const [showReleaseReportDialog, setShowReleaseReportDialog] = React.useState(false);
    const [lastUpdated, setLastUpdated] = React.useState(Date.now());
    const { orderBy, orderDirection, onChangeSort } = useTableSort(
        doctorStore.patientTable.orderBy,
        doctorStore.patientTable.orderDirection,
    );

    const selectedPatient = doctorStore.selectedPatient;

    // Used to track previous value
    const prevKeywordRef = React.useRef(searchKeyword);

    React.useEffect(() => {
        doctorStore.patientTable = { currentPage, debouncedSearchKeyword, orderBy, orderDirection };
    }, [currentPage, debouncedSearchKeyword, orderBy, orderDirection]);

    React.useEffect(() => {
        const getPatientsAsync = async () => {
            try {
                if (prevKeywordRef.current !== debouncedSearchKeyword) {
                    // set new previous value
                    prevKeywordRef.current = debouncedSearchKeyword;
                    if (currentPage !== 1) {
                        // force first page and return, the effect will pick up the changed page and rerun
                        setCurrentPage(1);
                        return;
                    }
                }

                const response = await API.getPatients({
                    limit: ROWS_PER_PAGE,
                    offset: (currentPage - 1) * ROWS_PER_PAGE,
                    order: orderDirection,
                    orderBy,
                    ...(debouncedSearchKeyword ? { keyword: debouncedSearchKeyword } : {}),
                });

                if (response) {
                    if (!debouncedSearchKeyword) {
                        setTotalRows(response.total);
                    }

                    setRows(response.results);
                    setSearchTotalRows(response.total);
                }
            } catch (error) {
                if (!isUnauthorizedError(error)) {
                    generalStore.errorMessage = t("error.loadPatientsList");
                }
                console.error(error);
            }
        };

        // Reset everything except warning dialog in doctorstore for a fresh start on optimizing a patient
        doctorStore.reset();

        getPatientsAsync();
    }, [currentPage, debouncedSearchKeyword, lastUpdated, orderBy, orderDirection, setTotalRows]);

    const handleClickMedicationCheck = (patient: IPatient) => {
        doctorStore.selectedPatient = patient;
        history.push(Routes.DOCTOR.MEDICATION_CHECK.replace(":uid", patient.uid));
    };

    const handleClickMedicationGroups = (patient: IPatient) => {
        doctorStore.selectedPatient = patient;
        history.push(Routes.DOCTOR.MEDICATION_GROUPS.replace(":uid", patient.uid));
    };

    const handleCloseReleaseReportDialog = () => {
        doctorStore.selectedPatient = null;
        setShowReleaseReportDialog(false);
    };

    const handleClickReleaseReport = async (patient: IPatient) => {
        doctorStore.selectedPatient = patient;
        setShowReleaseReportDialog(true);
    };

    const handleClickOptimize = (patient: IPatient) => {
        doctorStore.selectedPatient = patient;
        history.push(Routes.DOCTOR.OPTIMIZE.replace(":uid", patient.uid));
    };

    const handleSubmitReleaseReport = async (uid: string, reportReleased: boolean) => {
        try {
            const response = await API.releaseReport(uid, reportReleased);

            if (response) {
                setLastUpdated(Date.now());
                setShowReleaseReportDialog(false);
            }
        } catch (error) {
            if (!isUnauthorizedError(error)) {
                generalStore.errorMessage = t("error.releaseReport");
            }
            console.error(error);
        }
    };

    const handleClickDownloadReport = async (patient: IPatient) => {
        try {
            const report = await API.getPdfReport(patient.uid);

            fetchFile(report);
            const response = await API.markReportAsRead([patient.uid]);
            if (response) {
                setLastUpdated(Date.now());
            }
        } catch (error) {
            if (!isUnauthorizedError(error)) {
                generalStore.errorMessage = t("error.releaseReport");
            }
            console.error(error);
        }
    };

    return (
        <>
            <UserNavigation leftComponent={<h4>{t("screen.doctor.navigation.patient_overview")}</h4>} />
            <StandardContainer>
                {totalRows === null && searchTotalRows === null ? null : (
                    <>
                        <Searchbar
                            data-id="user_search"
                            onChange={setSearchKeyword}
                            placeholder={t("screen.doctor.patients.searchbar.placeholder")}
                            value={searchKeyword}
                            style={{ marginRight: 40, marginBottom: 16 }}
                        />
                        <PatientsTable
                            rows={rows}
                            onClickReleaseReport={handleClickReleaseReport}
                            onClickOptimize={handleClickOptimize}
                            onClickMedicationCheck={handleClickMedicationCheck}
                            onClickMedicationGroups={handleClickMedicationGroups}
                            onClickDownloadReport={handleClickDownloadReport}
                            onChangeSort={onChangeSort}
                            orderBy={orderBy}
                            orderDirection={orderDirection}
                        />
                        {totalRows !== null && searchTotalRows !== null && totalRows > 0 && (
                            <CustomPagination
                                data-id="pagination"
                                count={searchTotalRows}
                                rowsPerPage={ROWS_PER_PAGE}
                                page={currentPage}
                                onChangePage={setCurrentPage}
                            />
                        )}
                        {totalRows === 0 && <EmptyHint />}
                        {showReleaseReportDialog && selectedPatient && !selectedPatient.reportReleased && (
                            <ReleaseReportConfirmDialog
                                open={showReleaseReportDialog}
                                uid={selectedPatient.uid}
                                reportReleased={selectedPatient.reportReleased}
                                fullName={`${selectedPatient.lastname} ${selectedPatient.firstname}`}
                                birthdate={selectedPatient.birthdate}
                                onClose={handleCloseReleaseReportDialog}
                                onSubmit={handleSubmitReleaseReport}
                            />
                        )}
                    </>
                )}
            </StandardContainer>
        </>
    );
});
