import { Tabs } from "@material-ui/core";
import { observer } from "mobx-react";
import React from "react";
import { Routes } from "../../../routers/Routes";
import { NavigationTab } from "../../../ui/NavigationTab";
import { UserNavigation } from "../../../ui/UserNavigation";
import { UserManagement } from "./UserManagement";
import { StandardContainer } from "../../../containers/StandardContainer";
import { t } from "../../../../i18n/util";
import { News } from "../NewsSite/News";

const siteToIndex = {
    doctors: 0,
    patients: 1,
    news: 2,
};

type IProps = {
    activeTab: "patients" | "doctors" | "news";
};

export const PgxAdminSite = observer(({ activeTab }: IProps) => (
    <>
        <UserNavigation
            leftComponent={
                <Tabs value={siteToIndex[activeTab]} aria-label="nav tabs">
                    <NavigationTab
                        data-id="navigation_doctormanagement"
                        label={t("screen.pgx_admin.navigation.tab.doctor_management")}
                        to={Routes.PGX_ADMIN.DOCTORS}
                    />
                    <NavigationTab
                        data-id="navigation_patientmanagement"
                        label={t("screen.pgx_admin.navigation.tab.patient_management")}
                        to={Routes.PGX_ADMIN.PATIENTS}
                    />
                    <NavigationTab
                        data-id="navigation_news"
                        label={t("screen.pgx_admin.navigation.tab.news")}
                        to={Routes.PGX_ADMIN.NEWS}
                    />
                </Tabs>
            }
        />
        <StandardContainer>
            {/* UserManagement component needs to be remounted on site change, hence the key prop */}
            {activeTab === "news" ? <News key={activeTab} /> : <UserManagement key={activeTab} site={activeTab} />}
        </StandardContainer>
    </>
));
