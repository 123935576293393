import * as React from "react";
import { FormikErrors, FormikTouched, Field } from "formik";
import { CustomInputField } from "../ui/CustomInputField";
import * as Yup from "yup";
import { IMessageIDS, intlMessages, t } from "../../i18n/util";
import styled from "styled-components";
import { generalStore } from "../../stores/GeneralStore";
import { CustomSelect } from "../ui/CustomSelect";
import { ILocales } from "../../i18n/ILocales";

const HintText = styled.div`
    font-size: 14px;
    color: #878787;
`;

const FlexBetween = styled.div`
    display: flex;
    justify-content: space-between;
`;

export type IDoctorValues = {
    firstname: string;
    lastname: string;
    language: ILocales;
    pharmgenetixId: string | null;
    username: string;
};

type IComponentProps<Values> = {
    errors: FormikErrors<Values>;
    touched: FormikTouched<Values>;
};

export const getDoctorFormPages = (initialValues?: IDoctorValues) => {
    const heading = initialValues
        ? `${initialValues.lastname} ${initialValues.firstname}`
        : t("screen.pgx_admin.add_edit_doctor.dialog.title");

    return {
        initialValues: initialValues || {
            firstname: "",
            lastname: "",
            language: generalStore.locale,
            pharmgenetixId: "",
            username: "",
        },
        pages: [
            {
                heading,
                contentText: t("screen.pgx_admin.add_edit_doctor.dialog.personal_details.text"),
                validationSchema: Yup.object().shape({
                    firstname: Yup.string()
                        .required(t("screen.pgx_admin.add_edit_doctor.dialog.form.firstname.validation_error"))
                        .trim(),
                    lastname: Yup.string()
                        .required(t("screen.pgx_admin.add_edit_doctor.dialog.form.lastname.validation_error"))
                        .trim(),
                    language: Yup.string().trim(),
                    pharmgenetixId: Yup.string()
                        .nullable()
                        .trim(),
                }),
                component: ({ errors, touched }: IComponentProps<IDoctorValues>) => (
                    <>
                        <Field
                            data-id="add_edit_doctor_firstname"
                            component={CustomInputField}
                            label={t("screen.pgx_admin.add_edit_doctor.dialog.form.firstname.label")}
                            name="firstname"
                            type="text"
                            required
                            errorMessage={errors.firstname}
                            isTouched={touched.firstname}
                        />
                        <Field
                            data-id="add_edit_doctor_lastname"
                            component={CustomInputField}
                            label={t("screen.pgx_admin.add_edit_doctor.dialog.form.lastname.label")}
                            name="lastname"
                            type="text"
                            required
                            errorMessage={errors.lastname}
                            isTouched={touched.lastname}
                        />
                        <FlexBetween>
                            <Field
                                data-id="add_edit_doctor_pharmgenetix_id"
                                component={CustomInputField}
                                label={t("screen.pgx_admin.add_edit_doctor.dialog.form.pharmgenetix_id.label")}
                                name="pharmgenetixId"
                                type="text"
                                errorMessage={errors.pharmgenetixId}
                                isTouched={touched.pharmgenetixId}
                                style={{ width: "100%", marginRight: 8 }}
                            />
                            <Field
                                data-id="add_doctor_language"
                                component={CustomSelect}
                                label={t("screen.pgx_admin.add_edit_doctor.dialog.form.language")}
                                name="language"
                                type="text"
                                errorMessage={errors.language}
                                isTouched={touched.language}
                                options={Object.keys(intlMessages).map(lang => ({
                                    text: t(`language.${lang}` as IMessageIDS),
                                    value: lang,
                                }))}
                                style={{ width: "100%", marginLeft: 8 }}
                            />
                        </FlexBetween>
                    </>
                ),
            },
            {
                heading,
                contentText: t("screen.pgx_admin.add_edit_doctor.dialog.contact_details.text"),
                validationSchema: Yup.object().shape({
                    username: Yup.string()
                        .email(t("screen.login.form.email.validation_error"))
                        .required(t("screen.login.form.email.validation_error"))
                        .trim(),
                }),
                component: ({ errors, touched }: IComponentProps<IDoctorValues>) => (
                    <>
                        <Field
                            data-id="add_edit_doctor_email"
                            component={CustomInputField}
                            label={t("screen.pgx_admin.add_edit_doctor.dialog.form.email.label")}
                            name="username"
                            type="email"
                            required
                            errorMessage={errors.username}
                            isTouched={touched.username}
                        />
                        <HintText>{t("screen.pgx_admin.add_edit_doctor.dialog.form.email.helper_text")}</HintText>
                    </>
                ),
            },
        ],
    };
};
