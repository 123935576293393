import { Button, withStyles } from "@material-ui/core";
import { flatMap, mapValues, groupBy, uniq } from "lodash";
import { observer } from "mobx-react";
import * as React from "react";
import { Link, RouteChildrenProps } from "react-router-dom";
import styled from "styled-components";
import { t, tHtml } from "../../../../i18n/util";
import { API, isUnauthorizedError } from "../../../../network/API";
import { authStore } from "../../../../stores/AuthStore";
import { doctorStore } from "../../../../stores/DoctorStore";
import { generalStore } from "../../../../stores/GeneralStore";
import { optimizerStore } from "../../../../stores/OptimizerStore";
import { patientStore } from "../../../../stores/PatientStore";
import { IAtcCode, IAtcTree, IMedication, IPatient, ISubstance } from "../../../../types";
import { history } from "../../../routers/history";
import { Routes } from "../../../routers/Routes";
import { UserNavigation } from "../../../ui/UserNavigation";
import { Colors } from "../../../util/Colors";
import { Icon } from "../../../util/Icon";
import { ImageTriangle } from "../../../util/Images";
import { ATC_DEPTH_GROUP, getAtcGroup, getSubstances, getTranslation } from "../../../util/Optimizer";
import { AtcGroupsDropdown } from "./AtcGroupsDropdown";
import { InteractionsPreviewTable } from "./InteractionsPreviewTable";
import { SelectionTable } from "./SelectionTable";
import { Legend } from "../../../ui/Legend";

const StyledButton = withStyles({
    root: {
        height: 66,
        borderRadius: 0,
        width: "100%",
        marginTop: 25,
    },
})(Button);

const NavigationHeading = styled.h4`
    margin-left: 32px;
`;

const BackContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Container = styled.div`
    display: flex;
    flex-grow: 1;
`;

const ReplacementContainer = styled.div`
    min-width: 600px;
    max-width: 600px;
    box-shadow: 0 -2px 10px 0 rgba(124, 126, 136, 0.1), 0 8px 10px 0 rgba(101, 121, 222, 0.05);
    position: relative;
    margin-right: 26px;
    padding: 40px;
    padding-bottom: 106px;
`;

const StyledImageTriangle = styled(ImageTriangle)`
    position: absolute;
    left: 579px;
    top: 50%;
    transform: translateY(-50%);
`;

const InteractionContainer = styled.div`
    flex-grow: 1;
    padding: 53px 40px 40px 14px;
`;

const Heading = styled.h3`
    margin-bottom: 24px;
`;

const HeadingSmall = styled.h4`
    margin-bottom: 18px;
`;

const ExplanationText = styled.div`
    margin-bottom: 32px;
`;

const NoWrapBlock = styled.div`
    margin: 24px 0 16px 0;
    white-space: nowrap;
`;

const LegendContainer = styled.div`
    padding-right: 40px;
    padding-top: 40px;
`;

type IParams = {
    uid?: string;
};

type IProps = RouteChildrenProps<IParams>;

export const ManualOptimizeSite = observer(({ match }: IProps) => {
    const userIsPatient = authStore.userProfile?.scope.includes("patientPLUS");
    const uid = userIsPatient ? (authStore.userProfile?.user as IPatient).uid : match?.params.uid;
    const currentMedication = doctorStore.currentMedication;

    React.useEffect(() => {
        if (!doctorStore.selectedPatient && uid) {
            history.push(userIsPatient ? Routes.PATIENT.MEDICATION_CHECK : Routes.DOCTOR.OPTIMIZE.replace(":uid", uid));
        }
    }, [uid, userIsPatient]);

    if (!doctorStore.selectedPatient) {
        // TODO this is actually not allowed with hooks, observer hides the warnings!!!
        return null;
    }

    const manualSelectedGroup = doctorStore.selectedGroups[doctorStore.manualReplaceSubstance?.id || ""]?.[0]; // the group the user had to choose
    const autoSelectedGroup = optimizerStore.substances?.atcCodes[
        doctorStore.manualReplaceSubstance?.id || ""
    ]?.[0].substr(0, ATC_DEPTH_GROUP); // the group that is used when the substance is only in one group

    const [combinations, setCombinations] = React.useState<IMedication[]>([]);
    const [substitutes, setSubstitutes] = React.useState<ISubstance[]>([]);
    const [selectedSubstanceId, setSelectedSubstanceId] = React.useState(doctorStore.manualReplaceSubstance?.id || "");
    const [selectedAtcGroup, setSelectedAtcGroup] = React.useState(manualSelectedGroup || autoSelectedGroup || "");
    const [categoryAtcGroup, setCategoryAtcGroup] = React.useState<IAtcTree | null>(null);

    const currentCombination = combinations.find(
        combination => !!combination.medication.find(substance => substance.id === selectedSubstanceId),
    );

    React.useEffect(() => {
        const categoryAtcGroup = getAtcGroup(
            optimizerStore.substances?.atcTree || { name: "root", atcCode: "" },
            selectedAtcGroup,
            "category",
        );

        setCategoryAtcGroup(categoryAtcGroup);
    }, [selectedAtcGroup]);

    // It can happen that substances of medication are in the same or neighbored group
    // It doesn't make sense to replace something with a substance which is already in the medication
    const ignoredSubstances = React.useMemo(
        () =>
            (currentMedication?.medication || [])
                .filter(substance => substance.id !== doctorStore.manualReplaceSubstance?.id)
                .map(substance => substance.id),
        [currentMedication],
    );

    React.useEffect(() => {
        const loadInitialCombination = async () => {
            generalStore.isLoading = true;

            if (!doctorStore.selectedPatient || !categoryAtcGroup) {
                return;
            }

            const allSubstituteSubstanceIds = uniq(
                getSubstances(categoryAtcGroup).filter(
                    substanceId => substanceId && !ignoredSubstances.includes(substanceId),
                ),
            );

            const substitutesByAtcGroup = groupBy(allSubstituteSubstanceIds, s => s.substr(0, 5));

            try {
                const result = await API.calculate(
                    doctorStore.selectedPatient.uid,
                    (doctorStore.currentMedication?.medication || []).map(substance => {
                        if (substance.id === doctorStore.manualReplaceSubstance?.id) {
                            return allSubstituteSubstanceIds;
                        }

                        return substance.id;
                    }),
                    true,
                );

                setCombinations(result.combinations);

                const substitutesWithInteractions = mapValues(substitutesByAtcGroup, groupSubstitutes =>
                    flatMap(groupSubstitutes, substanceId =>
                        flatMap(
                            result.combinations,
                            combination => combination.medication.find(substance => substance.id === substanceId) || [],
                        ),
                    ),
                );

                // Check if one of the substance atc codes matches the selected atc group
                const substitutsWithAlternativeAtcCodes = flatMap(
                    substitutesWithInteractions,
                    atcCodes => atcCodes,
                ).filter(substitute =>
                    optimizerStore.substances?.atcCodes[substitute.id]?.some(substituteId =>
                        substituteId.startsWith(selectedAtcGroup),
                    ),
                );

                setSubstitutes(substitutsWithAlternativeAtcCodes);
            } catch (error) {
                if (!isUnauthorizedError(error)) {
                    generalStore.errorMessage = t("error.calculate");
                }
                console.error(error);
            }

            generalStore.isLoading = false;
        };

        loadInitialCombination();
    }, [ignoredSubstances, uid, categoryAtcGroup, selectedAtcGroup]);

    const [legendExpanded, setLegendExpanded] = React.useState(false);

    const handleChangeSelectedLevel = (atcCode: IAtcCode) => {
        setSelectedAtcGroup(atcCode);
    };

    const handleChangeSubstanceId = (atcCode: IAtcCode) => {
        setSelectedSubstanceId(atcCode);
    };

    const handleClickKeepOrSaveSubstance = async () => {
        if (uid) {
            if (userIsPatient) {
                // always set this medication instead of only when substanceId is different to prevent
                // losing unsaved changes for the patient after keeping the substance
                patientStore.medicationAfterManualReplace = currentCombination ?? null;
            }

            if (doctorStore.manualReplaceSubstance?.id !== selectedSubstanceId) {
                if (!userIsPatient) {
                    try {
                        await API.saveMedication(
                            uid,
                            (doctorStore.currentMedication?.medication || []).map(substance => {
                                if (substance.id === doctorStore.manualReplaceSubstance?.id) {
                                    return selectedSubstanceId;
                                }

                                return substance.id;
                            }),
                            "",
                        );
                    } catch (error) {
                        if (!isUnauthorizedError(error)) {
                            generalStore.errorMessage = t("error.saveMedication");
                        }
                        console.error(error);
                    }
                }
            }

            history.push(userIsPatient ? Routes.PATIENT.MEDICATION_CHECK : Routes.DOCTOR.OPTIMIZE.replace(":uid", uid));
        }
    };

    const toggleLegendExpanded = () => {
        setLegendExpanded(!legendExpanded);
    };

    if (!doctorStore.selectedPatient || !categoryAtcGroup) {
        return null;
    }

    return (
        <>
            {!userIsPatient && (
                <UserNavigation
                    leftComponent={
                        <BackContainer>
                            {uid && (
                                <Link to={Routes.DOCTOR.OPTIMIZE.replace(":uid", uid)}>
                                    <Icon name="arrowLeft" hoverColor={Colors.secondary} />
                                </Link>
                            )}
                            <NavigationHeading>
                                {t("screen.doctor.manual_optimize.navigation.replace_manually")}
                            </NavigationHeading>
                        </BackContainer>
                    }
                />
            )}
            <Container>
                <ReplacementContainer>
                    <Heading>{t("screen.doctor.manual_optimize.substance_picker.heading")}</Heading>
                    <AtcGroupsDropdown
                        atcGroupCategory={categoryAtcGroup}
                        ignoredSubstances={ignoredSubstances}
                        value={selectedAtcGroup}
                        onChangeAtcGroup={handleChangeSelectedLevel}
                    />
                    <NoWrapBlock>
                        {t("screen.doctor.manual_optimize.substance_picker.select_substance.text")}
                    </NoWrapBlock>
                    <SelectionTable
                        data={substitutes}
                        selectedSubstanceId={selectedSubstanceId}
                        onChangeSubstanceId={handleChangeSubstanceId}
                    />
                    <StyledButton variant="contained" color="primary" onClick={handleClickKeepOrSaveSubstance}>
                        {selectedSubstanceId === doctorStore.manualReplaceSubstance?.id
                            ? t("screen.doctor.manual_optimize.substance_picker.keep_substance.button.text")
                            : t("screen.doctor.manual_optimize.substance_picker.save_substance.button.text")}
                    </StyledButton>
                    <StyledImageTriangle />
                </ReplacementContainer>
                <InteractionContainer>
                    <HeadingSmall>{t("screen.doctor.manual_optimize.interaction_preview.heading")}</HeadingSmall>
                    <ExplanationText>
                        {tHtml("screen.doctor.manual_optimize.interaction_preview.explanation", {
                            substance: (
                                <b>
                                    {doctorStore.manualReplaceSubstance &&
                                        getTranslation(doctorStore.manualReplaceSubstance.id)}
                                </b>
                            ),
                        })}
                    </ExplanationText>
                    <InteractionsPreviewTable
                        data={currentCombination}
                        selectedSubstanceId={selectedSubstanceId}
                        canShowDetailInfo={!userIsPatient}
                        showLegend={legendExpanded}
                        onShowLegendToggled={toggleLegendExpanded}
                    />
                </InteractionContainer>
                {legendExpanded && (
                    <LegendContainer>
                        <Legend withEnzymes />
                    </LegendContainer>
                )}
            </Container>
        </>
    );
});
