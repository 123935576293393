import moment from "moment";
import React from "react";
import styled from "styled-components";
import { INewsValues } from "../../../../types";

type IProps = {
    news: INewsValues;
    hideDot?: boolean;
};

const Container = styled.div`
    background: #f3f3f3;
    padding: 30px;
    width: 100%;
    max-width: 900px;
    margin-bottom: 10px;
    position: relative;
`;

const Date = styled.small`
    display: block;
    padding-bottom: 10px;
`;

const Title = styled.h4`
    padding-bottom: 20px;
`;

const RedDot = styled.span`
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    top: 15px;
    right: 15px;
`;

export const UnreadNews = ({ news, hideDot }: IProps) => {
    return (
        <Container>
            {!hideDot && <RedDot />}
            <Date>{moment(news.showAfter).format("DD.MM.YYYY")}</Date>
            <Title>{news.title}</Title>
            <div dangerouslySetInnerHTML={{ __html: news.content }} />
        </Container>
    );
};
