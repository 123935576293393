import React from "react";
import { INewsValues } from "../../../../types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, withStyles } from "@material-ui/core";
import styled from "styled-components";
import moment from "moment";

const Title = styled.h4``;

const Date = styled.small`
    display: block;
    padding-bottom: 10px;
`;

const Summary = styled.div`
    display: flex;
    flex-flow: column;
`;

const Content = styled.div``;

const StyledExpansionPanel = withStyles({
    root: {
        width: "100%",
        maxWidth: "900px",
    },
})(ExpansionPanel);

type IProps = {
    news: INewsValues;
};

export const ReadNewsAccordion = ({ news }: IProps) => {
    return (
        <StyledExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Summary>
                    <Date>{moment(news.showAfter).format("DD.MM.YYYY")}</Date>
                    <Title>{news.title}</Title>
                </Summary>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Content dangerouslySetInnerHTML={{ __html: news.content }} />
            </ExpansionPanelDetails>
        </StyledExpansionPanel>
    );
};
