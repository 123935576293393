import { Button, withStyles } from "@material-ui/core";
import * as React from "react";
import { Link, RouteChildrenProps } from "react-router-dom";
import styled from "styled-components";
import { t } from "../../../../i18n/util";
import { doctorStore } from "../../../../stores/DoctorStore";
import { StandardContainer } from "../../../containers/StandardContainer";
import { Routes } from "../../../routers/Routes";
import { Legend } from "../../../ui/Legend";
import { UserNavigation } from "../../../ui/UserNavigation";
import { Colors } from "../../../util/Colors";
import { Icon } from "../../../util/Icon";
import { history } from "../../../routers/history";
import { ReplacementInteractionsTable } from "./ReplacementInteractionsTable";
import { observer } from "mobx-react";
import { generalStore } from "../../../../stores/GeneralStore";
import { API, isUnauthorizedError } from "../../../../network/API";
import { compact } from "lodash";

const StyledButton = withStyles({
    root: {
        width: 232,
        height: 56,
    },
})(Button);

const NavigationHeading = styled.h4`
    margin-left: 32px;
`;

const BackContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Container = styled(StandardContainer)`
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    padding-bottom: 106px;
`;

const Heading = styled.h3`
    margin-bottom: 16px;
`;

const Headline = styled.h3`
    margin-bottom: 24px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 40px;
`;

const Notice = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #878787;
    margin-top: 16px;
    & > * + * {
        margin-left: 8px;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-flow: column;
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    flex-flow: row;

    @media only screen and (max-width: 1190px) {
        flex-flow: column-reverse;
    }
`;

const Justify = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`;

type IParams = {
    uid?: string;
};

type IProps = RouteChildrenProps<IParams>;

export const AutoOptimizeSaveSite = observer(({ match }: IProps) => {
    const uid = match?.params.uid;

    React.useEffect(() => {
        if (!doctorStore.selectedPatient && uid) {
            history.push(Routes.DOCTOR.OPTIMIZE.replace(":uid", uid));
        }
    }, [uid]);

    const handleClickSaveMedication = async () => {
        if (uid) {
            generalStore.isLoading = true;

            try {
                await API.saveMedication(
                    uid,
                    (doctorStore.replacementMedication?.medication || []).map(substance => {
                        return substance.id;
                    }),
                    doctorStore.remarks || "",
                );
            } catch (error) {
                if (!isUnauthorizedError(error)) {
                    generalStore.errorMessage = t("error.saveMedication");
                }
                console.error(error);
            }

            generalStore.isLoading = false;

            if (doctorStore.selectedPatient) {
                history.push(Routes.DOCTOR.OPTIMIZE.replace(":uid", doctorStore.selectedPatient.uid));
            }
        }
    };

    return (
        <>
            <UserNavigation
                leftComponent={
                    <BackContainer>
                        {uid && (
                            <Link to={Routes.DOCTOR.OPTIMIZE_AUTO.replace(":uid", uid)}>
                                <Icon name="arrowLeft" hoverColor={Colors.secondary} />
                            </Link>
                        )}
                        <NavigationHeading>
                            {doctorStore.selectedPatient?.lastname} {doctorStore.selectedPatient?.firstname}
                        </NavigationHeading>
                    </BackContainer>
                }
            />
            <Container>
                <Content>
                    <Justify>
                        <TextContainer>
                            <Headline>{t("screen.doctor.optimize.replace_automatically.replacement.heading")}</Headline>
                            <p>{t("screen.doctor.optimize.replace_automatically.replacement.sub_heading")}.</p>
                        </TextContainer>
                        <ButtonContainer>
                            <StyledButton variant="contained" color="primary" onClick={handleClickSaveMedication}>
                                {t("screen.doctor.optimize.replace_automatically.replacement.save")}
                            </StyledButton>
                        </ButtonContainer>
                    </Justify>
                    <ReplacementInteractionsTable />
                    {doctorStore.remarks && (
                        <Notice>
                            <Icon name="comment" />
                            <span>{doctorStore.remarks}</span>
                        </Notice>
                    )}
                </Content>
                <Legend />
            </Container>
        </>
    );
});
