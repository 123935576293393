import { Button } from "@material-ui/core";
import * as React from "react";
import styled from "styled-components";
import { t } from "../../../../i18n/util";
import { ImageEmpty } from "../../../util/Images";
import { Routes } from "../../../routers/Routes";
import { doctorStore } from "../../../../stores/DoctorStore";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { authStore } from "../../../../stores/AuthStore";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 45px;
`;

const Heading = styled.div`
    width: 332px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 32px;
`;

const StyledImageEmpty = styled(ImageEmpty)`
    margin-bottom: 16px;
`;

export const NoMedicationHint = observer(() => {
    const userIsPatient = authStore.userProfile?.scope.includes("patientPLUS");

    return (
        <Container data-id="no_medication_hint">
            <StyledImageEmpty />
            <Heading>
                {userIsPatient
                    ? t("screen.patient.optimize.no_medication.heading")
                    : t("screen.doctor.optimize.no_medication.heading")}
            </Heading>
            {!userIsPatient && (
                <Button
                    data-id="to_medication_groups"
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={Routes.DOCTOR.MEDICATION_GROUPS.replace(":uid", doctorStore.selectedPatient?.uid || "")}
                    target="_blank"
                    style={{
                        width: 317,
                    }}
                >
                    {t("screen.doctor.optimize.no_medication.to_medication_groups")}
                </Button>
            )}
        </Container>
    );
});
