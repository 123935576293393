import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import { t } from "../../../../i18n/util";
import { Icon } from "../../../util/Icon";
import { newsStore } from "../../../../stores/NewsStore";
import { Routes } from "../../../routers/Routes";
import { UserNavigation } from "../../../ui/UserNavigation";
import { Colors } from "../../../util/Colors";
import { ReadNewsAccordion } from "./ReadNewsAccordion";
import { UnreadNews } from "./UnreadNews";
import { Link } from "react-router-dom";
import { API } from "../../../../network/API";
import { authStore } from "../../../../stores/AuthStore";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding: 40px;
    padding-bottom: 64px;
`;

const Headline = styled.h3`
    margin-bottom: 20px;
`;

const DescriptionText = styled.p`
    margin-bottom: 30px;
`;

const NavigationHeading = styled.h4`
    margin-left: 32px;
`;

const BackContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const NewsSite = observer(() => {
    React.useEffect(
        () => () => {
            const markNewsAsRead = async () => {
                try {
                    const response = await API.markNewsAsRead();
                    authStore.authProfile = response;
                    newsStore.markNewsAsRead();
                } catch (error) {
                    console.log(error);
                }
            };
            markNewsAsRead();
        },
        [],
    );

    return (
        <>
            <UserNavigation
                leftComponent={
                    <BackContainer>
                        <Link to={Routes.DOCTOR.ROOT}>
                            <Icon name="arrowLeft" hoverColor={Colors.secondary} />
                        </Link>
                        <NavigationHeading>{t("screen.doctor.navigation.news")}</NavigationHeading>
                    </BackContainer>
                }
            />
            <Container>
                <Headline>{t("screen.doctor.navigation.news")}</Headline>
                <DescriptionText>{t("screen.doctor.news.description")}</DescriptionText>
                {newsStore && newsStore.unreadNews.length
                    ? newsStore.unreadNews.map(news => <UnreadNews key={news.uid} news={news} />)
                    : null}
                {newsStore && newsStore.readNews.length
                    ? newsStore.readNews.map(news => <ReadNewsAccordion key={news.uid} news={news} />)
                    : null}
            </Container>
        </>
    );
});
