import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { generalStore } from "../../stores/GeneralStore";
import { CenteredContainer } from "../containers/CenteredContainer";
import { ForgotPasswordForm, IForgotPasswordValues } from "../forms/ForgotPasswordForm";
import { observer } from "mobx-react";

export const ForgotPasswordSite = observer(() => {
    const [success, setSuccess] = React.useState(false);
    const [message, setMessage] = React.useState("");

    const handleSubmit = async (model: IForgotPasswordValues) => {
        generalStore.isLoading = true;

        try {
            const requestResult = await API.resetPassword({ username: model.email });

            if (requestResult.success) {
                setSuccess(true);
                setMessage(t("screen.forgot_password.form.success"));
            }
        } catch (error) {
            setSuccess(false);
            setMessage(t("screen.forgot_password.form.error"));
        }

        generalStore.isLoading = false;
    };

    return (
        <CenteredContainer>
            <h4>{t("screen.forgot_password.title")}</h4>
            <div style={{ marginTop: 14, marginBottom: 35 }}>{t("screen.forgot_password.description")}</div>
            <ForgotPasswordForm success={success} message={message} onSubmit={handleSubmit} />
        </CenteredContainer>
    );
});
