import * as React from "react";
import errorimage from "../../assets/images/im_errorimage.svg";
import { t } from "../../i18n/util";
import { ImageError } from "../util/Images";
import { SiteContainer } from "../containers/CenteredContainer";
import styled from "styled-components";

const StyledImageError = styled(ImageError)`
    position: absolute;
    left: 0;
    top: 0;
    width: 90vw;
`;

export const NotFoundSite = () => (
    <SiteContainer>
        <StyledImageError />
        <p
            style={{
                color: "white",
                fontFamily: "Maven Pro",
                fontSize: 260,
                fontWeight: "bold",
                zIndex: 1,
            }}
        >
            404
        </p>
        <p
            style={{
                color: "white",
                zIndex: 1,
                fontWeight: "bold",
                fontSize: 16,
            }}
        >
            Es tut uns Leid, aber diese Seite ist leider nicht verfügbar.
        </p>
    </SiteContainer>
);
