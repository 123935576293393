import { flatMap, get } from "lodash";
import { t } from "../../i18n/util";
import { generalStore } from "../../stores/GeneralStore";
import { optimizerStore } from "../../stores/OptimizerStore";
import { IAtcCode, IAtcLevel, IAtcTree, ISubstanceId } from "../../types";

const EMPTY_OBJECT = {};

// According to https://confluence.allaboutapps.at/pages/viewpage.action?pageId=75597719
// ATC codes are structured 1, 2, 1, 1, 2
export const ATC_DEPTH_ROOT = 1;
export const ATC_DEPTH_CATEGORY = 3;
export const ATC_DEPTH_PARENT_GROUP = 4;
export const ATC_DEPTH_GROUP = 5;
export const ATC_DEPTH_SUBSTANCE = 7;

export const getAtcGroup = (atcTree: IAtcTree, atcCode: IAtcCode, level: IAtcLevel): IAtcTree => {
    const groupIndices = {
        root: 0,
        category: 1,
        parentGroup: 2,
        group: 3,
        substance: 4,
    };

    const levelAtcCodes = [
        atcCode.substr(0, ATC_DEPTH_ROOT),
        atcCode.substr(0, ATC_DEPTH_CATEGORY),
        atcCode.substr(0, ATC_DEPTH_PARENT_GROUP),
        atcCode.substr(0, ATC_DEPTH_GROUP),
        atcCode.substr(0, ATC_DEPTH_SUBSTANCE),
    ];

    const parentGroupPath = levelAtcCodes
        .slice(0, groupIndices[level] + 1)
        .map(key => `children.${key}`)
        .join(".");

    const parentGroupTree = get(atcTree, parentGroupPath) || EMPTY_OBJECT;

    return parentGroupTree;
};

export const getTranslation = (id: ISubstanceId | IAtcCode, atcLevel?: IAtcLevel): string => {
    return (
        optimizerStore.substances?.localizedNames[id]?.[generalStore.locale] ||
        (atcLevel && getAtcGroup(optimizerStore.substances?.atcTree || { atcCode: "", name: "" }, id, atcLevel).name) ||
        id
    );
};

export const getSubstances = (atcTree: IAtcTree): ISubstanceId[] => {
    if (!atcTree.substanceId) {
        return flatMap(Object.values(atcTree.children || {}), childAtcTree => getSubstances(childAtcTree));
    }
    return [atcTree.substanceId];
};
