// tslint:disable-next-line:typedef
export const Routes = {
    ROOT: "/",
    FORGOT_PASSWORD: "/forgotPassword",
    SET_PASSWORD: "/setPassword/:token",
    PGX_ADMIN: {
        ROOT: "/pgxadmin",
        PATIENTS: "/pgxadmin/patients",
        DOCTORS: "/pgxadmin/doctors",
        NEWS: "/pgxadmin/news",
    },
    DOCTOR: {
        ROOT: "/patients",
        OPTIMIZE: "/patients/:uid/optimize",
        OPTIMIZE_AUTO: "/patients/:uid/optimize/auto",
        OPTIMIZE_AUTO_REPLACE: "/patients/:uid/optimize/auto/replace",
        OPTIMIZE_MANUAL: "/patients/:uid/optimize/manual",
        MEDICATION_GROUPS: "/patients/:uid/medicationgroups",
        MEDICATION_GROUP_PRINT: "/patient-noheader/medicationgroups/print/:atcCode",
        MEDICATION_CHECK: "/patients/:uid/medicationcheck",
        NEWS: "/news",
    },
    PATIENT: {
        ROOT: "/patient",
        REPORT: "/patient/report",
        MEDICATION_GROUPS: "/patient/medicationgroups",
        MEDICATION_CHECK: "/patient/medicationcheck",
        OPTIMIZE: "/patient/optimize",
    },
};
