import { Box, Button, withStyles } from "@material-ui/core";
import { FieldInputProps, FormikProps } from "formik";
import * as React from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { t } from "../../i18n/util";
import { Colors } from "../util/Colors";
import { Icon } from "../util/Icon";
import { ImageFolder } from "../util/Images";

const DropzoneContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 88px;
    padding: 16px;
    background-color: #f9f9f9;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
`;

const StyledImageFolder = styled(ImageFolder)`
    width: 72px;
    height: 72px;
`;

const OpenDialogButton = withStyles({
    label: {
        letterSpacing: 0.5,
    },
})(Button);

type IProps<Values> = {
    errorMessage?: string;
    form: FormikProps<Values>;
    field: FieldInputProps<File>;
    isTouched?: boolean;
    showValidationErrorText?: boolean;
    label: string;
    required?: boolean;
    fileType: "pgx-report" | "pdf";
    previousFileName?: string;
    style?: React.CSSProperties;
    "data-id"?: string;
};

export const CustomDropzone = <Values,>({
    style,
    label,
    required,
    errorMessage,
    form,
    field,
    isTouched,
    fileType,
    showValidationErrorText = true,
    previousFileName,
    "data-id": dataId,
}: IProps<Values>) => {
    const { getRootProps, getInputProps, open } = useDropzone({
        accept: `.${fileType}`,
        noClick: true,
        noKeyboard: true,
        multiple: false,
        onDrop: files => {
            form.setFieldValue(field.name, files[0] || null);
        },
    });

    const showError = isTouched && !!errorMessage;
    const hasSelectedFile = !!field.value;

    return (
        <div style={style}>
            <Box mb="16px" fontWeight="bold">
                {required ? `${label} *` : label}
            </Box>
            <DropzoneContainer {...getRootProps()}>
                {hasSelectedFile || previousFileName ? (
                    <Box display="flex" alignItems="center" fontWeight="bold">
                        <Icon
                            name={fileType === "pgx-report" ? "pgx" : fileType}
                            size={32}
                            style={{ marginRight: 16 }}
                        />{" "}
                        {field.value?.name || previousFileName}
                    </Box>
                ) : (
                    <StyledImageFolder />
                )}
                <OpenDialogButton onClick={open}>
                    {hasSelectedFile || previousFileName
                        ? t("dropzone.button.label_selected")
                        : t("dropzone.button.label_empty")}
                </OpenDialogButton>
                <input data-id={dataId} {...getInputProps()} />
            </DropzoneContainer>
            {showValidationErrorText && (
                <span
                    style={{
                        color: Colors.danger,
                        display: "block",
                        minHeight: 18,
                        width: "100%",
                        marginTop: 4,
                        marginBottom: 10,
                        fontSize: 14,
                    }}
                >
                    {showError && errorMessage}
                </span>
            )}
        </div>
    );
};
