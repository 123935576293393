import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import { observer } from "mobx-react";
import React from "react";
import { generalStore } from "../../stores/GeneralStore";

const SlideTransition = (props: any) => {
    return <Slide {...props} direction="down" />;
};

export const ErrorToast = observer(() => {
    const handleClose = () => (generalStore.errorMessage = undefined);

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={!!generalStore.errorMessage}
            key={generalStore.errorMessage}
            onClose={handleClose}
            autoHideDuration={5000}
            message={generalStore.errorMessage}
            TransitionComponent={SlideTransition}
            ContentProps={{
                style: {
                    backgroundColor: "#FB5607",
                },
            }}
        />
    );
});
