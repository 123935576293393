import { TextField, TextFieldProps } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FieldInputProps, FormikProps, FormikValues } from "formik";
import { isEqual } from "lodash";
import React from "react";
import { Colors } from "../util/Colors";
import styled from "styled-components";
import { Icon } from "../util/Icon";

type IOption = {
    value: string | number;
    label: string | number;
};

type IProps<Values extends FormikValues> = TextFieldProps & {
    errorMessage?: string;
    field: FieldInputProps<string>;
    form: FormikProps<Values>;
    isTouched?: boolean;
    showValidationErrorText: boolean;
    options: IOption[];
    "data-id"?: string;
    selectedItemsHeight?: number;
};

const SelectedOptionsContainer = styled.div`
    border: 2px solid #e1e1e1;
    max-height: ${({ style }) => style && style.maxHeight};
    overflow-y: scroll;
`;

const SelectedOptionRow = styled.div`
    padding: 0 10px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #e1e1e1;
    height: 50px;

    &:last-child {
        border-bottom: none;
    }
`;

const IconContainer = styled.div`
    margin-right: 10px;

    &:hover {
        cursor: pointer;
    }
`;

export const CustomAutocompleteMultiSelect = <Values extends FormikValues>({
    style,
    label,
    type,
    required,
    errorMessage,
    field,
    form,
    isTouched,
    showValidationErrorText = true,
    options,
    "aria-label": ariaLabel,
    "data-id": dataId,
    selectedItemsHeight,
}: IProps<Values>) => {
    const showError = isTouched && !!errorMessage;
    const [selectOptions, setSelectOptions] = React.useState<IOption[]>(options || []);
    const [selectedOptions, setSelectedOptions] = React.useState<IOption[]>([]);
    const [inputValue, setInputValue] = React.useState<string>("");

    React.useEffect(() => {
        function initData() {
            const selectedValues: (string | number)[] = (field.value as unknown) as (string | number)[];
            const newSelectOptions: IOption[] = [];
            const newSelectedOptions: IOption[] = [];

            for (const option of options) {
                if (selectedValues.includes(option.value)) {
                    newSelectedOptions.push(option);
                } else {
                    newSelectOptions.push(option);
                }
            }

            if (!isEqual(newSelectOptions, selectOptions)) {
                setSelectOptions(newSelectOptions);
            }

            if (!isEqual(newSelectedOptions, selectedOptions)) {
                setSelectedOptions(newSelectedOptions);
            }
        }

        initData();
    }, [field.value, options, selectOptions, selectedOptions]);

    const autoCompleteRef = React.useRef();

    const handleSelect = (selectedOption: IOption | null) => {
        if (selectedOption) {
            removeSelectOption(selectedOption);
            addToSelectedOptions(selectedOption);
            setInputValue("");
        }
    };

    const handleRemove = (selectedOption: IOption | null) => {
        if (selectedOption) {
            removeSelectedOption(selectedOption);
            addToSelectOptions(selectedOption);
        }
    };

    const addToSelectOptions = (selectedOption: IOption) => {
        const newSelectedOptions = [...selectOptions, selectedOption];
        newSelectedOptions.sort();

        setSelectOptions(newSelectedOptions);
    };

    const removeSelectOption = (selectedOption: IOption) => {
        const selectOptionIdx = selectOptions.findIndex((option: IOption) => option.value === selectedOption.value);
        const newSelectOptions = [...selectOptions];
        newSelectOptions.splice(selectOptionIdx, 1);

        setSelectOptions(newSelectOptions);
    };

    const addToSelectedOptions = (selectedOption: IOption) => {
        const newSelectedOptions = [...selectedOptions, selectedOption];
        newSelectedOptions.sort();

        setSelectedOptions(newSelectedOptions);
        form.setFieldValue(field.name, getValues(newSelectedOptions));
    };

    const removeSelectedOption = (selectedOption: IOption) => {
        const selectOptionIdx = selectedOptions.findIndex((option: IOption) => option.value === selectedOption.value);
        const newSelectedOptions = [...selectedOptions];
        newSelectedOptions.splice(selectOptionIdx, 1);

        setSelectedOptions(newSelectedOptions);
        form.setFieldValue(field.name, getValues(newSelectedOptions));
    };

    const getValues = (selectedOptions: IOption[]): (string | number)[] => {
        const values = selectedOptions.map(option => option.value);

        return values;
    };

    const handleInputChange = (event: React.ChangeEvent<{}>) => {
        if (typeof (event.target as HTMLInputElement).value === "string") {
            setInputValue((event.target as HTMLInputElement).value);
        }
    };

    const areOptionsSelected = (): boolean => {
        return !!selectedOptions.length;
    };

    return (
        <div style={style}>
            <Autocomplete
                ref={autoCompleteRef}
                options={selectOptions}
                getOptionLabel={(option: IOption) => String(option.label)}
                // onChange={(_: any, option: IOption | null) => form.setFieldValue(field.name, option?.value)}
                onChange={(_: any, option: IOption | null) => handleSelect(option)}
                onBlur={field.onBlur}
                value={null}
                style={{ marginBottom: 20 }}
                onInputChange={handleInputChange}
                inputValue={inputValue}
                openOnFocus
                ListboxProps={{
                    "data-id": `${dataId}_options`,
                }}
                renderInput={(params: TextFieldProps) => (
                    <TextField
                        {...params}
                        name={field.name}
                        label={required ? `${label} *` : label}
                        fullWidth
                        type={type}
                        error={showError}
                        aria-label={ariaLabel}
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            "data-id": dataId,
                        }}
                        InputProps={{
                            ...params.InputProps,
                            style: {
                                ...params.InputProps?.style,
                                padding: "4px 62px 4px 4px",
                            },
                        }}
                    />
                )}
            />

            {areOptionsSelected() ? (
                <SelectedOptionsContainer style={{ maxHeight: selectedItemsHeight || "none" }}>
                    {selectedOptions.map(selectedOption => (
                        <SelectedOptionRow key={selectedOption.value}>
                            <IconContainer>
                                <Icon name="x" onClick={() => handleRemove(selectedOption)} />
                            </IconContainer>
                            <p>{selectedOption.label}</p>
                        </SelectedOptionRow>
                    ))}
                </SelectedOptionsContainer>
            ) : null}

            {showValidationErrorText && (
                <span
                    style={{
                        color: Colors.danger,
                        display: "block",
                        minHeight: 18,
                        width: "100%",
                        marginTop: 4,
                        marginBottom: 10,
                        fontSize: 14,
                    }}
                >
                    {showError && errorMessage}
                </span>
            )}
        </div>
    );
};
