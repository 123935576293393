import React from "react";
import styled from "styled-components";
import { t } from "../../i18n/util";
import { Icon } from "../util/Icon";

const InfoContainerWrapper = styled.div`
    display: flex;
    background: #f3f3f3;
    padding: 20px 10px;
    margin-bottom: 20px;
`;

const InfoContainerHeadline = styled.strong`
    display: inline-block;
    margin: 4px 0 10px;
`;

type IProps = {
    headline: string;
    text: string;
};

export const InfoContainer = ({ headline, text }: IProps) => (
    <InfoContainerWrapper>
        <div>
            <Icon style={{ transform: "rotate(180deg)", marginRight: 10 }} color="#f6bf44" name="info" />
        </div>
        <div>
            <InfoContainerHeadline>{headline}</InfoContainerHeadline>
            <br />
            <span>{text}</span>
        </div>
    </InfoContainerWrapper>
);
