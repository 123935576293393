import { FormControl, InputLabel, MenuItem, Select, withStyles } from "@material-ui/core";
import { Field, FieldInputProps, FormikErrors, FormikProps, FormikTouched } from "formik";
import { uniqBy, countBy } from "lodash";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { t } from "../../i18n/util";
import { optimizerStore } from "../../stores/OptimizerStore";
import { IAtcCode, ISubstanceId } from "../../types";
import { Colors } from "../util/Colors";
import { getAtcGroup, getTranslation } from "../util/Optimizer";

const StyledSelect = withStyles({
    root: {
        "&:focus": {
            backgroundColor: "inherit",
        },
    },
})(Select);

const StyledMenuItem = withStyles({
    root: {
        height: 58,
        borderBottom: "2px solid #e1e1e1",
    },
})(MenuItem);

const HintText = styled.div`
    font-size: 14px;
    color: #878787;
`;

export type IGroupSelectonValues = {
    [substanceId in ISubstanceId]: IAtcCode[];
};

type IComponentProps<Values> = {
    errors: FormikErrors<Values>;
    touched: FormikTouched<Values>;
};

type ISelectProps = {
    field: FieldInputProps<string[]>;
    form: FormikProps<IGroupSelectonValues>;
    isTouched: boolean;
};

export const getGroupSelectionFormPages = (initialValues: IGroupSelectonValues, multiSelection: boolean) => {
    const substanceIds = Object.keys(initialValues);

    return {
        initialValues,
        pages: substanceIds.map(substanceId => ({
            heading: getTranslation(substanceId),
            contentText: multiSelection
                ? t("screen.doctor.select_group.dialog.multiple_groups.text")
                : t("screen.doctor.select_group.dialog.single_group.text"),
            validationSchema: Yup.object().shape({ [substanceId]: Yup.array().min(1) }),
            component: ({ errors, touched }: IComponentProps<IGroupSelectonValues>) => (
                <Field
                    data-id="add_edit_doctor_email"
                    component={({ field, form }: ISelectProps) => {
                        const inputLabel = React.useRef<HTMLLabelElement>(null);
                        const [labelWidth, setLabelWidth] = React.useState<number | undefined>(0);

                        const validationError = multiSelection
                            ? t("screen.doctor.select_group.dialog.dialog.form.multiple.group.validation_error")
                            : t("screen.doctor.select_group.dialog.dialog.form.single.group.validation_error");

                        const possibleAtcCodes = optimizerStore.substances?.atcCodes[substanceId] || [];

                        const groupOptionsArray = uniqBy(
                            possibleAtcCodes.map(atcCode => {
                                const group = getAtcGroup(
                                    optimizerStore.substances?.atcTree || { atcCode: "", name: "" },
                                    atcCode,
                                    "group",
                                );

                                return {
                                    atcCode: group.atcCode,
                                    name: getTranslation(group.atcCode, "group"),
                                };
                            }),
                            "atcCode", // It can happen, that more than one group atcCode can be found in the same group, but we only want to show the group once
                        );

                        // Add parent groups name when there is a group with the same name multiple times
                        const groupOptions = groupOptionsArray.map(option => {
                            const occurences = groupOptionsArray.filter(({ name }) => option.name === name).length;

                            return occurences > 1
                                ? {
                                      ...option,
                                      name: `${option.name} ${
                                          occurences > 1 ? `(${getTranslation(option.atcCode, "parentGroup")})` : ""
                                      }`,
                                  }
                                : option;
                        });

                        React.useEffect(() => {
                            setLabelWidth(inputLabel.current?.offsetWidth);
                        }, []);

                        const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
                            const value = multiSelection
                                ? (event.target.value as string[])
                                : [event.target.value as string];

                            form.setFieldValue(substanceId, value, true);
                        };

                        return (
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel ref={inputLabel} id="atc_groups_label">
                                    {t("dropdown.label.atcGroups")}
                                </InputLabel>
                                <StyledSelect
                                    variant="outlined"
                                    labelWidth={labelWidth}
                                    label={t("dropdown.label.atcGroups")}
                                    onChange={handleChange}
                                    onBlur={field.onBlur}
                                    name={field.name}
                                    multiple={multiSelection}
                                    defaultValue={multiSelection ? undefined : ""}
                                    value={multiSelection ? field.value : field.value[0]}
                                    inputProps={{
                                        name: "atcGroups",
                                        id: "atc_groups",
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                boxShadow:
                                                    "0 -2px 10px 0 rgba(124, 126, 136, 0.1), 0 8px 10px 0 rgba(101, 121, 222, 0.05)",
                                            },
                                        },
                                        MenuListProps: {
                                            style: {
                                                padding: 0,
                                            },
                                        },
                                        anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                        getContentAnchorEl: null,
                                    }}
                                >
                                    {groupOptions.map(group => (
                                        <StyledMenuItem key={group.atcCode} value={group.atcCode}>
                                            {group.name}
                                        </StyledMenuItem>
                                    ))}
                                </StyledSelect>
                                <span
                                    style={{
                                        color: Colors.danger,
                                        display: "block",
                                        minHeight: 18,
                                        width: "100%",
                                        marginTop: 4,
                                        marginBottom: 10,
                                        fontSize: 14,
                                    }}
                                >
                                    {touched[substanceId] && errors[substanceId] && validationError}
                                </span>
                            </FormControl>
                        );
                    }}
                    name={substanceId}
                    required
                />
            ),
        })),
    };
};
