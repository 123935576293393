import { Button } from "@material-ui/core";
import * as React from "react";
import styled from "styled-components";
import { IMessageIDS, t } from "../../../../i18n/util";
import { ImageEmpty } from "../../../util/Images";

const EmptyListHintContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 45px;
    align-items: center;
`;

const StyledImageEmpty = styled(ImageEmpty)`
    margin-bottom: 16px;
`;

type IProps = {
    site: "patients" | "doctors";
    onClickNewUser: () => void;
};

export const EmptyHint = ({ site, onClickNewUser }: IProps) => (
    <EmptyListHintContainer data-id="empty_list_hint">
        <StyledImageEmpty />
        <div style={{ marginBottom: 25 }}>
            <b>{t(`screen.pgx_admin.${site}.empty_list.text` as IMessageIDS)}</b>
        </div>
        <Button
            data-id="empty_list_hint_new_user"
            variant="contained"
            color="primary"
            onClick={onClickNewUser}
            style={{
                width: 270,
            }}
        >
            {t(`screen.pgx_admin.${site}.empty_list.add.button.label` as IMessageIDS)}
        </Button>
    </EmptyListHintContainer>
);
