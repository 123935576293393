import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    withStyles,
} from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import { Form, Formik, FormikErrors, FormikHelpers, FormikTouched } from "formik";
import * as React from "react";
import { t, IMessageIDS } from "../../i18n/util";
import { Colors } from "../util/Colors";
import { Icon } from "../util/Icon";

const StyledDialog = withStyles({
    paper: {
        height: 520,
    },
})(Dialog);

type IPage<Values> = {
    heading?: React.ReactNode;
    contentText?: string;
    validationSchema: any;
    component: React.FunctionComponent<{ errors: FormikErrors<Values>; touched: FormikTouched<Values> }>;
};

type IProps<Values> = {
    initialValues: Values;
    pages: IPage<Values>[];
    open: boolean;
    onClose: () => void;
    onSubmit: (model: Values) => void;
    submitButtonTextId?: IMessageIDS;
    "data-id"?: string;
};

export const StepDialog = <Values,>({
    pages,
    initialValues,
    open,
    onClose,
    onSubmit,
    submitButtonTextId,
    "data-id": dataId,
}: IProps<Values>) => {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = (model: Values, helpers: FormikHelpers<Values>) => {
        helpers.setTouched({});
        helpers.setSubmitting(false);

        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const Page = pages[activeStep].component;
    const heading = pages[activeStep].heading;
    const contentText = pages[activeStep].contentText;

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={activeStep < pages.length - 1 ? handleNext : onSubmit}
            validationSchema={pages[activeStep].validationSchema}
            validateOnBlur
            validateOnChange
        >
            {({ errors, touched, isSubmitting }) => (
                <StyledDialog
                    PaperProps={{ "data-id": dataId } as any}
                    open={open}
                    onClose={onClose}
                    aria-labelledby="dialog_title"
                    fullWidth
                    disableBackdropClick
                    maxWidth="md"
                >
                    <DialogTitle disableTypography id="title" data-id="title" style={{ position: "relative" }}>
                        {heading && <h3>{heading}</h3>}
                        <Icon
                            name="x"
                            data-id="dialog_close_button"
                            onClick={onClose}
                            hoverColor={Colors.secondary}
                            style={{ position: "absolute", top: 0, right: 0 }}
                        />
                    </DialogTitle>
                    <DialogContent>
                        {contentText && (
                            <DialogContentText style={{ color: "#1E1E1E", fontWeight: "bold", marginBottom: 32 }}>
                                {contentText}
                            </DialogContentText>
                        )}
                        <Form id="formData">
                            <Page errors={errors} touched={touched} />
                        </Form>
                    </DialogContent>
                    <DialogActions style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                        <div>
                            {activeStep > 0 && (
                                <Icon
                                    data-id="dialog_back"
                                    name="arrowLeft"
                                    onClick={handleBack}
                                    hoverColor={Colors.secondary}
                                    display="block"
                                />
                            )}
                            {pages.length > 1 &&
                                pages.map((_, index) => (
                                    <FiberManualRecord
                                        key={index}
                                        style={{
                                            fontSize: 12,
                                            color: activeStep >= index ? Colors.primary : "#d5d5d5",
                                        }}
                                    />
                                ))}
                        </div>
                        <Button
                            data-id="dialog_next_submit"
                            type="submit"
                            form="formData"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            style={{ width: 190 }}
                        >
                            {activeStep < pages.length - 1
                                ? t("dialog.button_next")
                                : t(submitButtonTextId || "dialog.button_save")}
                        </Button>
                    </DialogActions>
                </StyledDialog>
            )}
        </Formik>
    );
};
