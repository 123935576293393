import * as React from "react";
import styled from "styled-components";
import { Colors } from "../util/Colors";
import { ImageLogo } from "../util/Images";

export const SiteContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    padding: 24px;
    background: ${Colors.primary}; /* Old browsers */
    background: -moz-linear-gradient(-45deg, ${Colors.primary} 0%, ${Colors.secondary} 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, ${Colors.primary} 0%, ${Colors.secondary} 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        135deg,
        ${Colors.primary} 0%,
        ${Colors.secondary} 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='${Colors.primary}', endColorstr='${Colors.secondary}', GradientType=1); /* IE6-9 fallback on horizontal gradient */
`;

const StyledImageLogo = styled(ImageLogo)`
    height: 43px;
`;

const LoginContainer = styled.div`
    background: #ffffff;
    border-radius: 4px;
    width: 100%;
    max-width: 465px;
    padding: 75px 61px 75px 61px;
    box-shadow: 0 -2px 10px 0 rgba(124, 126, 136, 0.1), 0 8px 10px 0 rgba(101, 121, 222, 0.05);
`;

const LogoContainer = styled.div`
    text-align: center;
    margin-bottom: 40px;
`;

export const CenteredContainer = ({ children }: { children: React.ReactNode }) => (
    <SiteContainer>
        <LoginContainer>
            <LogoContainer>
                <StyledImageLogo />
            </LogoContainer>
            {children}
        </LoginContainer>
    </SiteContainer>
);
