import { observer } from "mobx-react";
import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import { authStore } from "../../stores/AuthStore";
import { Routes } from "./Routes";

const getRedirect = (scope: string[] = [], location: any): { pathname: string; state: { from: string } } | null => {
    if (scope.includes("admin")) {
        return {
            pathname: Routes.PGX_ADMIN.ROOT,
            state: { from: location },
        };
    } else if (scope.includes("doctor")) {
        return {
            pathname: Routes.DOCTOR.ROOT,
            state: { from: location },
        };
    } else if (scope.includes("patient") || scope.includes("patientPLUS")) {
        return {
            pathname: Routes.PATIENT.ROOT,
            state: { from: location },
        };
    }

    return null;
};

export const NoAuthOnlyRoute: any = observer(({ component: Component, ...props }: any): any => {
    if (!authStore.isRehydrated) {
        return null;
    }

    const previousLocation = props.location.state?.from;

    const redirectTo = previousLocation || getRedirect(authStore.userProfile?.scope, props.location);
    const isAuthenticated = authStore.isAuthenticated;

    return (
        <Route
            {...props}
            render={(props: any): any =>
                isAuthenticated && redirectTo ? <Redirect to={redirectTo} /> : <Component {...props} />
            }
        />
    );
});
