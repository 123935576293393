import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { compact, flatMap } from "lodash";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import { t } from "../../../../i18n/util";
import { authStore } from "../../../../stores/AuthStore";
import { doctorStore } from "../../../../stores/DoctorStore";
import { optimizerStore } from "../../../../stores/OptimizerStore";
import { patientStore } from "../../../../stores/PatientStore";
import { IPatient, ISubstance, ISubstanceId } from "../../../../types";
import { history } from "../../../routers/history";
import { Routes } from "../../../routers/Routes";
import { BadgeCollection } from "../../../ui/BadgeCollection";
import { Legend } from "../../../ui/Legend";
import { LoadingOverlay } from "../../../ui/LoadingOverlay";
import { SubstanceAutocompleteSearchbar } from "../../../ui/SubstanceAutocompleteSearchbar";
import { Badge } from "../../../util/Badge";
import { getTranslation } from "../../../util/Optimizer";
import { EmptyHint } from "./EmptyHint";

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Content = styled.div`
    flex-grow: 1;
    margin-right: 40px;
`;

const Heading = styled.h3`
    margin-bottom: 24px;
`;

const TextContainer = styled.div`
    margin-bottom: 32px;
`;

export const MedicationCheck = observer(() => {
    const [selectedSubstances, setSelectedSubstances] = React.useState<ISubstance[]>([]);

    React.useEffect(() => {
        const getMedicationGroups = async () => {
            if (authStore.userProfile?.scope.includes("doctor") && doctorStore.selectedPatient) {
                await patientStore.loadGroups(doctorStore.selectedPatient);
            }

            if (!doctorStore.selectedPatient && !patientStore.showMedicationCheck) {
                // this will bug if the optimizerStore was not rehydrated before, site will be redirected to groups on refresh then
                history.push(Routes.PATIENT.MEDICATION_GROUPS);
            }
        };

        getMedicationGroups();
    }, []);

    let substances: ISubstance[] = [];

    const user = authStore.userProfile?.scope.includes("doctor")
        ? doctorStore.selectedPatient
        : (authStore.userProfile?.user as IPatient);

    if (user) {
        substances = user.canOptimize
            ? compact(
                  Object.keys(optimizerStore.substances?.atcCodes || {}).map(
                      substanceId => patientStore.substanceCalculateResults?.[substanceId],
                  ),
              )
            : flatMap(patientStore.groups, group => group.substances);
    }

    const handleSelectSubstance = async (substanceId: ISubstanceId, tradeName?: string) => {
        const tradeNameSubstanceIds = Object.keys(optimizerStore.substances?.tradeNames || {});
        const tradeNameSubstances = flatMap(tradeNameSubstanceIds, tradeNameSubstanceId => {
            const tradeNames = optimizerStore.substances?.tradeNames[tradeNameSubstanceId] || [];

            if (tradeName && tradeNames.includes(tradeName)) {
                const tradeNameSubstance = substances.find(substance => substance.id === tradeNameSubstanceId);
                const isSubstanceSelected = selectedSubstances.find(substance => substance.id === tradeNameSubstanceId);

                return tradeNameSubstance && !isSubstanceSelected ? [tradeNameSubstance] : [];
            }

            return [];
        });

        const substance = substances.find(substance => substance.id === substanceId);
        if (substance) {
            setSelectedSubstances([...selectedSubstances, ...(tradeName ? tradeNameSubstances : [substance])]);
        }
    };

    if (patientStore.isLoading) {
        return <LoadingOverlay />;
    }

    return (
        <Container>
            <Content>
                <Heading>{t("screen.patient.medication_check.heading")}</Heading>
                <TextContainer>{t("screen.patient.medication_check.text")}</TextContainer>
                {substances.length > 0 ? (
                    <div>
                        <SubstanceAutocompleteSearchbar
                            placeholder={t("screen.doctor.optimize.actions.searchbar.placeholder")}
                            data-id="active_agent_search"
                            substances={substances
                                .map(substance => substance.id)
                                .filter(
                                    substanceId =>
                                        !selectedSubstances.find(
                                            selectedSubstance => selectedSubstance.id === substanceId,
                                        ),
                                )}
                            onSelect={handleSelectSubstance}
                            resetOnSelect
                            style={{ marginBottom: 16 }}
                        />
                        <Table data-id="medication_check_table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: "40%" }}>{t("table.heading.active_agent")}</TableCell>
                                    <TableCell>{t("table.heading.single_medication")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedSubstances.map(substance => (
                                    <TableRow key={substance.id}>
                                        <TableCell style={{ width: "40%" }}>{getTranslation(substance.id)}</TableCell>
                                        <TableCell>
                                            <BadgeCollection>
                                                <Badge value={substance.single.fc} />
                                                {substance.single.warnTL && (
                                                    <Badge
                                                        value="warning"
                                                        color="gray"
                                                        tooltipText={t("optimizer.legend.section.warning.badge.text")}
                                                    />
                                                )}
                                            </BadgeCollection>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                ) : (
                    <EmptyHint />
                )}
            </Content>
            <Legend />
        </Container>
    );
});
