import { Field, Form, Formik } from "formik";
import * as React from "react";
import * as Yup from "yup";
import { CustomInputField } from "../ui/CustomInputField";
import { t } from "../../i18n/util";
import { Button } from "@material-ui/core";
import { Colors } from "../util/Colors";
import styled from "styled-components";

const MessageContainer = styled.div.attrs((props: any) => ({
    success: props.success,
}))`
    display: flex;
    align-items: center;
    height: 16px;
    margin-bottom: 7px;
    color: ${props => (props.success ? Colors.okay : Colors.danger)};
`;

export type IForgotPasswordValues = {
    email: string;
};

type IProps = {
    success: boolean;
    message: string;
    onSubmit: (model: IForgotPasswordValues) => void;
};

export const ForgotPasswordForm = ({ success, message, onSubmit }: IProps) => (
    <Formik
        initialValues={{
            email: "",
        }}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
            email: Yup.string()
                .email(t("screen.forgot_password.form.email.validation_error"))
                .required(t("screen.forgot_password.form.email.validation_error"))
                .trim(),
        })}
        validateOnBlur
        validateOnChange
    >
        {({ errors, touched, isSubmitting }) => (
            <Form>
                <Field
                    data-id="forgot_password_email"
                    component={CustomInputField}
                    label={t("screen.forgot_password.form.email.label")}
                    name="email"
                    type="email"
                    required
                    autoComplete="username"
                    errorMessage={errors.email}
                    isTouched={touched.email}
                />
                <MessageContainer success={success}>{!!message && message}</MessageContainer>
                <Button
                    data-id="forgot_password_submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isSubmitting}
                    type="submit"
                    size="large"
                >
                    {t("screen.forgot_password.form.submit")}
                </Button>
            </Form>
        )}
    </Formik>
);
