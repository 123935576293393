import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import { t } from "../../../../i18n/util";
import { doctorStore } from "../../../../stores/DoctorStore";
import { BadgeCollection } from "../../../ui/BadgeCollection";
import { Badge } from "../../../util/Badge";
import { Icon } from "../../../util/Icon";
import { getTranslation } from "../../../util/Optimizer";

const FlexHeight = withStyles({
    root: {
        flexBasis: 0,
        flexGrow: 1,
    },
})(TableContainer);

const FixedTag = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    line-height: 12px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.5px;
    color: #878787;
`;

const ReplacedText = styled.div`
    font-size: 14px;
    color: #878787;
    text-decoration: line-through;
`;

export const ReplacementInteractionsTable = observer(() => (
    <FlexHeight>
        <Table data-id="replacement_interaction_table" aria-label="table" stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell>{t("table.heading.active_agent")}</TableCell>
                    <TableCell>{t("table.heading.single_medication")}</TableCell>
                    <TableCell>{t("table.heading.interaction")}</TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {doctorStore.replacementMedication?.medication.map((substance, index) => {
                    if (!doctorStore.currentMedication) {
                        return null;
                    }

                    const currentName = getTranslation(doctorStore.currentMedication?.medication[index].id);
                    const replacementName = getTranslation(substance.id);

                    return (
                        <TableRow data-id={`row_${index + 1}`} key={substance.id}>
                            <TableCell data-id="active_agent">
                                <b>{replacementName}</b>
                                {currentName !== replacementName && <ReplacedText>{currentName}</ReplacedText>}
                            </TableCell>
                            <TableCell data-id="single_medication">
                                <BadgeCollection>
                                    <Badge value={substance.single.fc} />
                                    {substance.single.warnTL && (
                                        <Badge
                                            value="warning"
                                            color="gray"
                                            tooltipText={t("optimizer.legend.section.warning.badge.text")}
                                        />
                                    )}
                                </BadgeCollection>
                            </TableCell>
                            <TableCell data-id="interaction">
                                <BadgeCollection>
                                    <Badge value={substance.interaction?.fc} />
                                    {substance.single.warnTL && (
                                        <Badge
                                            value="warning"
                                            color="gray"
                                            tooltipText={t("optimizer.legend.section.warning.badge.text")}
                                        />
                                    )}
                                </BadgeCollection>
                            </TableCell>
                            <TableCell data-id="actions" align="right">
                                {doctorStore.fixedSubstances?.includes(substance.id) && (
                                    <FixedTag>
                                        <Icon name="check" color="#878787" size={16} /> {t("table.action.fixed")}
                                    </FixedTag>
                                )}
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    </FlexHeight>
));
