import React from "react";
import { Formik, Form, Field, FormikErrors } from "formik";
import { t } from "../../i18n/util";
import { CustomInputField } from "../ui/CustomInputField";
import { Button } from "@material-ui/core";
import * as Yup from "yup";
import styled from "styled-components";
import { Colors } from "../util/Colors";
import { Link } from "react-router-dom";
import { Routes } from "../routers/Routes";

const ForgotPasswordContainer = styled.div`
    text-align: right;
`;

const StyledLink = styled(Link)`
    font-family: "Maven Pro";
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration: none;
    color: ${Colors.primary};
    &:visited {
        color: ${Colors.primary};
    }
`;

const ErrorContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${Colors.danger};
    font-size: 16px;
    height: 52px;
    padding: 8px 0;
`;

export type ILoginValues = {
    email: string;
    password: string;
};

const getErrorMessage = (errors: FormikErrors<ILoginValues>) => {
    if (errors.email) {
        if (errors.password) {
            return t("screen.login.form.validation_error_email_and_password");
        }

        return errors.email;
    } else if (errors.password) {
        return errors.password;
    }

    return null;
};

type IProps = {
    error?: string;
    onSubmit: (model: ILoginValues) => void;
};

export const LoginForm = ({ error, onSubmit }: IProps) => (
    <Formik
        initialValues={{
            email: "",
            password: "",
        }}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
            email: Yup.string()
                .email(t("screen.login.form.email.validation_error"))
                .required(t("screen.login.form.email.validation_error"))
                .trim(),
            password: Yup.string().required(t("screen.login.form.password.validation_error")),
        })}
        validateOnBlur={false}
        validateOnChange={false}
    >
        {({ errors, touched, isSubmitting }) => (
            <Form>
                <Field
                    data-id="login_email"
                    component={CustomInputField}
                    label={t("screen.login.form.email.label")}
                    name="email"
                    type="email"
                    required
                    autoComplete="username"
                    isTouched={touched.email}
                    showValidationErrorText={false}
                    style={{ marginBottom: 32 }}
                />
                <Field
                    data-id="login_password"
                    component={CustomInputField}
                    label={t("screen.login.form.password.label")}
                    name="password"
                    type="password"
                    required
                    autoComplete="current-password"
                    showValidationErrorText={false}
                    isTouched={touched.password}
                    style={{ marginBottom: 8 }}
                />
                <ForgotPasswordContainer>
                    <StyledLink to={Routes.FORGOT_PASSWORD} data-id="forgot_password_link">
                        {t("screen.login.form.forgot_password")}
                    </StyledLink>
                </ForgotPasswordContainer>
                <ErrorContainer>{getErrorMessage(errors) || error}</ErrorContainer>
                <Button
                    data-id="login_submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isSubmitting}
                    type="submit"
                    size="large"
                >
                    {t("screen.login.form.submit")}
                </Button>
            </Form>
        )}
    </Formik>
);
