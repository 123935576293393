import { Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from "@material-ui/core";
import { Formik, Form, Field, FormikErrors } from "formik";
import * as Yup from "yup";
import React from "react";
import { t } from "../../../../i18n/util";
import { CustomInputField } from "../../../ui/CustomInputField";
import { Icon } from "../../../util/Icon";
import { Colors } from "../../../util/Colors";
import { INewsValues } from "../../../../types";
import { API } from "../../../../network/API";
import { CustomTextEditor } from "../../../ui/CustomTextEditor";

type IProps = {
    isOpen: boolean;
    defaultValues?: INewsValues;
    onClose: () => void;
    onSuccess: () => void;
};

type IPutNewsFormValues = {
    title: string;
    content: string;
};

const StyledDialogContent = withStyles({
    root: {
        padding: "10px 0",
    },
})(DialogContent);

export const NewsModal = ({ isOpen, onClose, onSuccess, defaultValues }: IProps) => {
    const onSubmit = (values: IPutNewsFormValues) => {
        try {
            const data: INewsValues = {
                title: values.title,
                content: values.content,
            };

            if (!defaultValues) {
                data.showAfter = new Date().toISOString();
            }

            if (defaultValues) {
                data.uid = defaultValues.uid;
            }

            API.putNews(data).then(() => {
                if (onSuccess) {
                    onSuccess();
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Dialog open={isOpen} fullWidth onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle disableTypography id="title" data-id="title" style={{ position: "relative" }}>
                <h3>{defaultValues ? t("news_modal.edit_news") : t("news_modal.create_news")} </h3>
                <Icon
                    name="x"
                    data-id="dialog_close_button"
                    onClick={onClose}
                    hoverColor={Colors.secondary}
                    style={{ position: "absolute", top: 0, right: 0 }}
                />
            </DialogTitle>
            <StyledDialogContent>
                <Formik
                    initialValues={{
                        title: defaultValues ? defaultValues.title : "",
                        content: defaultValues ? defaultValues.content : "",
                    }}
                    onSubmit={onSubmit}
                    validationSchema={Yup.object().shape({
                        title: Yup.string().required(t("news_modal.form.error.title")),
                        content: Yup.string().required(t("news_modal.form.error.content")),
                    })}
                    validateOnChange={true}
                    validateOnMount={true}
                >
                    {({ touched, submitForm, isValid, isSubmitting }) => (
                        <>
                            <Form>
                                <Field
                                    data-id="title"
                                    component={CustomInputField}
                                    label={t("news_modal.form.label.title")}
                                    name="title"
                                    required
                                    isTouched={touched.title}
                                    showValidationErrorText={false}
                                    style={{ marginBottom: 32 }}
                                />
                                <Field
                                    data-id="content"
                                    component={CustomTextEditor}
                                    label={t("news_modal.form.label.content")}
                                    name="content"
                                    multiline
                                    required
                                    rows={4}
                                    isTouched={touched.content}
                                    showValidationErrorText={false}
                                    style={{ marginBottom: 32 }}
                                />
                            </Form>
                            <DialogActions>
                                <Button onClick={onClose} color="primary">
                                    {t("dialog.button_cancel")}
                                </Button>
                                <Button
                                    onClick={() => {
                                        submitForm();
                                    }}
                                    variant="contained"
                                    color="primary"
                                    disabled={!isValid || isSubmitting}
                                >
                                    {t("news_modal.publish")}
                                </Button>
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </StyledDialogContent>
        </Dialog>
    );
};
