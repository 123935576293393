import {
    FormControlLabel,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    withStyles,
} from "@material-ui/core";
import * as React from "react";
import { t } from "../../../../i18n/util";
import { ISubstance, ISubstanceId } from "../../../../types";
import { BadgeCollection } from "../../../ui/BadgeCollection";
import { Badge } from "../../../util/Badge";
import { Colors } from "../../../util/Colors";
import { Icon } from "../../../util/Icon";
import { getTranslation } from "../../../util/Optimizer";

const StyledFormControlLabel = withStyles({
    label: {
        fontSize: 16,
    },
})(FormControlLabel);

const StyledRadio = withStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
})(Radio);

const StyledTableRow = withStyles({
    root: {
        cursor: "pointer",
    },
})(TableRow);

type IProps = {
    data?: ISubstance[];
    selectedSubstanceId: ISubstanceId;
    onChangeSubstanceId: (substanceId: ISubstanceId) => void;
};

export const SelectionTable = ({ data, selectedSubstanceId, onChangeSubstanceId }: IProps) => {
    return (
        <RadioGroup value={selectedSubstanceId}>
            <Table data-id="selection_table" aria-label="table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("table.heading.selection")}</TableCell>
                        <TableCell>{t("table.heading.single_medication_short")}</TableCell>
                        <TableCell>{t("table.heading.interaction")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data &&
                        data.map((substance, index) => (
                            <StyledTableRow
                                onClick={() => {
                                    if (substance.id) {
                                        onChangeSubstanceId(substance.id);
                                    }
                                }}
                                data-id={`row_${index + 1}`}
                                key={substance.id}
                                style={{
                                    backgroundColor:
                                        substance.id === selectedSubstanceId ? Colors.background : undefined,
                                }}
                            >
                                <TableCell data-id="selection">
                                    <StyledFormControlLabel
                                        value={substance.id}
                                        control={
                                            <StyledRadio
                                                color="primary"
                                                checkedIcon={<Icon name="radioOn" />}
                                                icon={<Icon name="radioOff" />}
                                                disableRipple
                                            />
                                        }
                                        label={<b>{getTranslation(substance.id)}</b>}
                                    />
                                </TableCell>
                                <TableCell data-id="single_medication">
                                    <BadgeCollection>
                                        <Badge value={substance.single.fc} />
                                        {substance.single.warnTL && (
                                            <Badge
                                                value="warning"
                                                color={substance.id === selectedSubstanceId ? "white" : "gray"}
                                            />
                                        )}
                                    </BadgeCollection>
                                </TableCell>
                                <TableCell data-id="interaction">
                                    <BadgeCollection>
                                        <Badge value={substance.interaction?.fc} />
                                        {substance.single.warnTL && (
                                            <Badge
                                                value="warning"
                                                color={substance.id === selectedSubstanceId ? "white" : "gray"}
                                            />
                                        )}
                                    </BadgeCollection>
                                </TableCell>
                            </StyledTableRow>
                        ))}
                </TableBody>
            </Table>
        </RadioGroup>
    );
};
