import { Dialog, DialogTitle, Button, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import React from "react";
import { IMessageIDS, t } from "../../i18n/util";

type IProps = {
    isOpen: boolean;
    title: string;
    actionText: string;
    action: () => void;
    onClose: () => void;
};

export const ConfirmEmailDialog = ({ isOpen, action, onClose, title, actionText }: IProps) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t("confirm_resend_dialog.cancel.label")}
                </Button>
                <Button variant="contained" onClick={action} color="primary" autoFocus>
                    {actionText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
