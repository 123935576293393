import * as React from "react";
import styled from "styled-components";
import { Icon } from "../util/Icon";
import { Colors } from "../util/Colors";

const Heading = styled.h4`
    margin-bottom: 8px;
`;

const Container = styled.div`
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #e1e1e1;
    border-color: ${({ open }: { open: boolean }) => (open ? Colors.primary : "#E1E1E1")};
    padding: 8px;
`;

const Description = styled.div`
    font-size: 14px;
    color: #878787;
`;

const Centered = styled.div`
    display: flex;
    align-items: center;
`;

const ContentSummary = styled.div`
    display: inline-block;
    margin-right: ${({ showMargin }: { showMargin?: boolean }) => (showMargin ? "28px" : undefined)};
`;

const StyledIcon = styled(Icon)`
    user-select: none;
`;

type IProps = {
    heading: string;
    description: string;
    sectionSummary?: React.ReactNode;
    initialOpen?: boolean;
    children: React.ReactNode;
    className?: string;
    collapsible?: boolean;
};

export const Section = ({
    heading,
    description,
    sectionSummary,
    initialOpen,
    children,
    className,
    collapsible,
}: IProps) => {
    const [open, setOpen] = React.useState(!collapsible || !!initialOpen);

    const handleToggleOpen = () => {
        setOpen(!open);
    };

    return (
        <div className={className}>
            <Container open={open}>
                <div>
                    <Heading>{heading}</Heading>
                    {description && <Description>{description}</Description>}
                </div>
                <Centered>
                    <ContentSummary showMargin={collapsible}>{sectionSummary}</ContentSummary>
                    {collapsible && (
                        <StyledIcon
                            name={open ? "chevronUp" : "chevronDown"}
                            color={open ? Colors.primary : undefined}
                            onClick={handleToggleOpen}
                        />
                    )}
                </Centered>
            </Container>
            {open && children}
        </div>
    );
};
