import { FormControl, InputLabel, MenuItem, Select, withStyles } from "@material-ui/core";
import { every } from "lodash";
import * as React from "react";
import styled from "styled-components";
import { t } from "../../../../i18n/util";
import { IAtcCode, IAtcLevel, IAtcTree, ISubstanceId } from "../../../../types";
import { getTranslation } from "../../../util/Optimizer";

const StyledSelect = withStyles({
    root: {
        "&:focus": {
            backgroundColor: "inherit",
        },
    },
})(Select);

const StyledMenuItem = withStyles({
    root: {
        overflow: "hidden",
        padding: 0,
    },
})(MenuItem);

const HintText = styled.div`
    font-size: 14px;
    color: #878787;
`;

const Indent = styled.div`
    display: inline-block;
    width: 8px;
`;

const UnderlinedBlock = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    border-bottom: ${({ hideBorder }: { hideBorder: boolean }) => (hideBorder ? undefined : "2px solid #e1e1e1")};
    height: 56px;
`;

const Spacing = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    padding-right: 8px;
`;

const GroupName = styled.div``;

const getAtcLevel = (atcCode: string): IAtcLevel =>
    ({
        1: "root",
        3: "category",
        4: "parentGroup",
        5: "group",
        6: "substance",
    }[atcCode.length]);

type IProps = {
    atcGroupCategory: IAtcTree;
    value: string;
    ignoredSubstances: ISubstanceId[];
    onChangeAtcGroup: (atcGroup: IAtcCode) => void;
};

const getChildrenCount = (atcGroup: IAtcTree, ignoredSubstances: ISubstanceId[]) =>
    Object.values(atcGroup.children || {}).map(
        atcGroupChild => !ignoredSubstances.includes(atcGroupChild?.substanceId || ""),
    ).length;

export const AtcGroupsDropdown = ({ atcGroupCategory, value, onChangeAtcGroup, ignoredSubstances }: IProps) => {
    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = React.useState<number | undefined>(0);

    React.useEffect(() => {
        setLabelWidth(inputLabel.current?.offsetWidth);
    }, []);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        onChangeAtcGroup(event.target.value as string);
    };

    const atcCategoryCodes = Object.keys(atcGroupCategory.children || {});

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel ref={inputLabel} htmlFor="atc_groups">
                {t("dropdown.label.atcGroups")}
            </InputLabel>
            <StyledSelect
                variant="outlined"
                labelWidth={labelWidth}
                value={value}
                onChange={handleChange}
                title={getTranslation(value, getAtcLevel(value))}
                renderValue={(value: any) => getTranslation(value, getAtcLevel(value))}
                inputProps={{
                    name: "atcGroups",
                    id: "atc_groups",
                    "data-id": "atc_groups-dropdown",
                }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            boxShadow: "0 -2px 10px 0 rgba(124, 126, 136, 0.1), 0 8px 10px 0 rgba(101, 121, 222, 0.05)",
                        },
                    },
                    MenuListProps: {
                        style: {
                            padding: 0,
                        },
                    },
                    anchorOrigin: { vertical: "bottom", horizontal: "left" },
                    getContentAnchorEl: null,
                }}
            >
                {atcCategoryCodes.map((atcParentGroupCode, index) => {
                    const atcGroup = atcGroupCategory.children?.[atcParentGroupCode];

                    const atcGroupCodes = Object.keys(atcGroup?.children || {});
                    if (
                        !atcGroup?.atcCode ||
                        every(
                            atcGroupCodes.map(
                                groupAtcCode =>
                                    getChildrenCount(
                                        atcGroup?.children?.[groupAtcCode] || { name: "root", atcCode: "" },
                                        ignoredSubstances,
                                    ) === 0,
                            ),
                        )
                    ) {
                        return null;
                    }

                    return [
                        <StyledMenuItem value={atcGroup.atcCode} data-id={`parent_group_${index}`}>
                            <Indent />
                            <UnderlinedBlock hideBorder={false}>
                                <Indent />
                                <Spacing>
                                    <GroupName>{getTranslation(atcGroup.atcCode, "parentGroup")}</GroupName>
                                    {/* <HintText>
                                {sum(
                                    Object.keys(atcGroup.children || {}).map(groupAtcCode =>
                                        getChildrenCount(
                                            atcGroup.children?.[groupAtcCode] || { atcCode: "", name: "" },
                                            ignoredSubstances,
                                        ),
                                    ),
                                )}{" "}
                                {t(
                                    "screen.doctor.manual_optimize.substance_picker.dropdown.option.substance_count.text",
                                )}
                            </HintText> */}
                                </Spacing>
                            </UnderlinedBlock>
                        </StyledMenuItem>,
                        atcGroupCodes.map((groupAtcCode, index) => {
                            const group = atcGroup.children?.[groupAtcCode];

                            if (!group || !group.atcCode || getChildrenCount(group, ignoredSubstances) === 0) {
                                return null;
                            }

                            return (
                                <StyledMenuItem
                                    value={group.atcCode}
                                    key={group.atcCode}
                                    data-id={`child_group_${index}`}
                                >
                                    <Indent />
                                    <Indent />
                                    <Indent />
                                    <UnderlinedBlock
                                        hideBorder={getChildrenCount(atcGroup, ignoredSubstances) === index + 1}
                                    >
                                        <Indent />
                                        <Indent />
                                        <Spacing>
                                            <GroupName>{getTranslation(group.atcCode, "group")}</GroupName>
                                            {/* <HintText>
                                        <Indent />
                                        <Indent />
                                        <Spacing>
                                            <GroupName>{getTranslation(group.atcCode, "group")}</GroupName>
                                            {/* <HintText>
                                        {getChildrenCount(group, ignoredSubstances)}{" "}
                                        {t(
                                            "screen.doctor.manual_optimize.substance_picker.dropdown.option.substance_count.text",
                                        )}
                                    </HintText> */}
                                        </Spacing>
                                    </UnderlinedBlock>
                                </StyledMenuItem>
                            );
                        }),
                    ];
                })}
            </StyledSelect>
        </FormControl>
    );
};
