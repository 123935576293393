import styled from "styled-components";

export const BadgeCollection = styled.div`
    display: flex;
    align-items: center;

    * + * {
        margin-left: 8px;
    }
`;
