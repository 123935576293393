import { action, observable } from "mobx";
import moment from "moment";
import { API } from "../network/API";
import { INewsValues } from "../types";
import { authStore } from "./AuthStore";

class NewsStore {
    @observable hasUnreadNews = false;
    @observable readNews: INewsValues[] = [];
    @observable unreadNews: INewsValues[] = [];

    @action markNewsAsRead() {
        this.hasUnreadNews = false;
    }

    @action async load() {
        try {
            const news = await getNewsRaw();

            if (news && news.length > 0) {
                const sortedNews = sortNews(news);
                this.unreadNews = sortedNews.unreadNews;
                this.readNews = sortedNews.readNews;
            }

            if (this.unreadNews.length > 0 && !isOnNewsSite()) {
                this.hasUnreadNews = true;
            }
        } catch (error) {
            console.log(error);
        }
    }

    @action wipe() {
        this.hasUnreadNews = false;
        this.readNews = [];
        this.unreadNews = [];
    }
}

async function getNewsRaw() {
    const newsResponse = await API.getNews();
    return newsResponse.results;
}

function isOnNewsSite() {
    return window.location.pathname.includes("news");
}

function sortNews(news: INewsValues[]): { readNews: INewsValues[]; unreadNews: INewsValues[] } {
    if (!authStore.authProfile || authStore.authProfile.newsCheckedAt === null) {
        return { readNews: [], unreadNews: news };
    }

    const newsCheckedDate = new Date(authStore.authProfile.newsCheckedAt);
    const splittedNews: { readNews: INewsValues[]; unreadNews: INewsValues[] } = { readNews: [], unreadNews: [] };

    news.forEach(news => {
        const newsDate = new Date(news.showAfter as string);

        if (newsDate < newsCheckedDate) {
            splittedNews.readNews.push(news);
        } else {
            splittedNews.unreadNews.push(news);
        }
    });

    return splittedNews;
}

const newsStore = new NewsStore();

export { newsStore };
