import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    DialogActions,
    CircularProgress,
    withStyles
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import React from "react";
import { t } from "../../i18n/util"; import { CustomInputField } from "./CustomInputField";
import * as Yup from "yup";
import { API } from "../../network/API";
import styled from "styled-components";
import { SummaryRequestDoctor } from "../../types";
import { fetchFile } from "../../components/util/fetchFile"
import { IMedication } from "../../types";
import { generalStore } from "../../stores/GeneralStore";


type IProps = {
    uid: string;
    isOpen: boolean;
    onClose: () => void;
    currentMedication: IMedication;
};

const Description = styled.p`
    margin-bottom: 20px;
`;

const DownloadIndicator = withStyles({
    svg: {
        margin: "6px",
    },
    root: {
        color: "white",
    }
})(CircularProgress);

export function SummaryDialog({ isOpen, onClose, uid, currentMedication }: IProps) {
    const [loading, setLoading] = React.useState(false);

    async function submit(doctor: SummaryRequestDoctor) {
        setLoading(true)
        let file
        try {
            file = await API.getSummary(uid, { doctor, expectedMedication: currentMedication.medication })
        } catch (e) {
            if (e.statusCode === 400) {
                generalStore.errorMessage = t("summary_dialog.medication_mismatch_error");
            } else {
                generalStore.errorMessage = t("summary_dialog.generic_error");
            }
            setLoading(false)
            onClose()
        }

        fetchFile(file)
        setLoading(false)
        onClose()
    };

    return (
        <Dialog open={isOpen} fullWidth maxWidth="sm">
            <DialogTitle id="alert-dialog-title">{t("summary_dialog.headline")}</DialogTitle>
            <DialogContent>
                <Description>{t("summary_dialog.description")}</Description>
                <Formik
                    initialValues={{
                        firstname: "",
                        lastname: "",
                        field: "",
                    }}
                    onSubmit={submit}
                    validationSchema={Yup.object().shape({
                        firstname: Yup.string().required(t("summary_dialog.form.firstname_error")),
                        lastname: Yup.string().required(t("summary_dialog.form.lastname_error")),
                        field: Yup.string(),
                    })}
                    validateOnBlur={false}
                    validateOnChange={true}
                >
                    {({ errors, touched, isValid }) => (
                        <Form>
                            <Field
                                data-id="add_doctor_firstname"
                                component={CustomInputField}
                                label={t("summary_dialog.label.firstname")}
                                name="firstname"
                                type="text"
                                required
                                autoComplete="firstname"
                                isTouched={touched.firstname}
                                errorMessage={errors.firstname}
                                showValidationErrorText={true}
                                style={{ marginBottom: 32 }}
                            />
                            <Field
                                data-id="add_doctor_lastname"
                                component={CustomInputField}
                                label={t("summary_dialog.label.lastname")}
                                name="lastname"
                                type="text"
                                errorMessage={errors.lastname}
                                required
                                autoComplete="lastname"
                                isTouched={touched.lastname}
                                showValidationErrorText={true}
                                style={{ marginBottom: 32 }}
                            />
                            <Field
                                data-id="add_doctor_field"
                                component={CustomInputField}
                                label={t("summary_dialog.label.field")}
                                name="field"
                                type="text"
                                errorMessage={errors.field}
                                autoComplete="field"
                                isTouched={touched.field}
                                showValidationErrorText={true}
                                style={{ marginBottom: 32 }}
                            />
                            <DialogActions>
                                <Button onClick={onClose} color="primary">
                                    {t("confirm_resend_dialog.cancel.label")}
                                </Button>
                                <Button disabled={loading || !isValid} type="submit" variant="contained" color="primary" autoFocus>
                                    {t("summary_dialog.form.submit.text")}
                                    { loading && <DownloadIndicator/> }
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};
