import { Button, Tooltip } from "@material-ui/core";
import { compact, isEqual } from "lodash";
import { observer } from "mobx-react";
import * as React from "react";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { t } from "../../../../i18n/util";
import { API } from "../../../../network/API";
import { doctorStore } from "../../../../stores/DoctorStore";
import { IEnzyme, IMedication, IOptimizerMedication } from "../../../../types";
import { StandardContainer } from "../../../containers/StandardContainer";
import { CustomDialog } from "../../../dialogs/CustomDialog";
import { ReplacementRemarksDialog } from "../../../dialogs/ReplacementRemarksDialog";
import { history } from "../../../routers/history";
import { Routes } from "../../../routers/Routes";
import { OptimizeLoadingScreen } from "../../../ui/OptimizeLoadingScreen";
import { Section } from "../../../ui/Section";
import { UserNavigation } from "../../../ui/UserNavigation";
import { Colors } from "../../../util/Colors";
import { Icon } from "../../../util/Icon";
import { Combinations } from "./Combinations";
import { toJS } from "mobx";

const NavigationHeading = styled.h4`
    margin-left: 32px;
`;

const BackContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Heading = styled.h3`
    margin-bottom: 14px;
`;

const TextContainer = styled.div`
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
`;

const InfoButtonText = styled.span`
    letter-spacing: 1px;
    margin-right: 8px;
`;

const StyledSection = styled(Section)`
    margin-bottom: 16px;
`;

const CombinationCountContainer = styled.div`
    display: flex;
    align-items: center;
    & > * + * {
        margin-left: 8px;
    }
`;

const CombinationCount = ({ count }: { count: number }) => (
    <CombinationCountContainer>
        <Icon name="pill" />
        <span>
            {count}{" "}
            {count === 1
                ? t("screen.doctor.auto_optimize.section.combination")
                : t("screen.doctor.auto_optimize.section.combinations")}
        </span>
    </CombinationCountContainer>
);

type IParams = {
    uid?: string;
};

type IProps = RouteChildrenProps<IParams>;

export const AutoOptimizeSite = observer(({ match }: IProps) => {
    const uid = match?.params.uid;

    React.useEffect(() => {
        if (!doctorStore.selectedPatient && uid) {
            history.push(Routes.DOCTOR.OPTIMIZE.replace(":uid", uid));
        }
    }, [uid]);

    if (!doctorStore.selectedPatient) {
        // TODO this is actually not allowed with hooks, observer hides the warnings!!!
        return null;
    }

    const [isLoading, setIsLoading] = React.useState(doctorStore.optimizerResultSameLevel.length === 0);
    // const [differentLevelCombinations, setDifferentLevelCombinations] = React.useState<IOptimizerMedication[]>([]);
    const [showDetailInfo, setShowDetailInfo] = React.useState(false);
    const [showNoticeDialog, setShowNoticeDialog] = React.useState(false);
    const [optimizerError, setOptimizerError] = React.useState("");

    React.useEffect(() => {
        const loadResults = async () => {
            if (uid && doctorStore.currentMedication && doctorStore.optimizerResultSameLevel.length === 0) {
                try {
                    const optimizerResult = await API.optimize(
                        uid,
                        doctorStore.fixedSubstances || [],
                        doctorStore.currentMedication.medication.map(substance => substance.id),
                        doctorStore.selectedGroups,
                    );

                    doctorStore.optimizerResultSameLevel = optimizerResult.combinations;
                    // setDifferentLevelCombinations(whatever)
                    setIsLoading(false);
                } catch (error) {
                    console.error(error);
                    if (error.statusCode === 408) {
                        setOptimizerError(t("error.optimizer_timeout"));
                    } else {
                        setOptimizerError(t("error.optimize"));
                    }
                }
            }
        };

        // Resest values in case user goes back in history or clicks the back button from AutoOptimizeSaveSite
        doctorStore.replacementMedication = null;
        doctorStore.remarks = "";

        loadResults();
    }, [uid]);

    const handleToggleShowDetailInfo = () => {
        setShowDetailInfo(!showDetailInfo);
    };

    const redirectToReplacement = () => {
        if (doctorStore.selectedPatient) {
            history.push(Routes.DOCTOR.OPTIMIZE_AUTO_REPLACE.replace(":uid", doctorStore.selectedPatient.uid));
        }
    };

    const handleSelectCombination = (combination: IOptimizerMedication) => {
        doctorStore.replacementMedication = { ...combination, medication: compact(combination.medication) };

        // use toJS because comparing mobx observables seems not to work
        if (isEqual(toJS(combination), toJS(doctorStore.optimizerResultSameLevel[0]))) {
            redirectToReplacement();
        } else {
            setShowNoticeDialog(true);
        }
    };

    const handleCloseNoticeDialog = () => {
        setShowNoticeDialog(false);
    };

    const handleSubmitNoticeDialog = (notice: string) => {
        doctorStore.remarks = notice;
        redirectToReplacement();
    };

    const handleCloseErrorDialog = () => {
        setOptimizerError("");
        if (uid) {
            history.push(Routes.DOCTOR.OPTIMIZE.replace(":uid", uid));
        }
    };

    if (isLoading) {
        return (
            <>
                <OptimizeLoadingScreen />
                {optimizerError && (
                    <CustomDialog
                        open={!!optimizerError}
                        onSubmit={handleCloseErrorDialog}
                        title={t("common.error")}
                        message={optimizerError}
                    />
                )}
            </>
        );
    }

    const hasMedicationEnzymes = (medication: IMedication | null) => {
        if (medication === null) return false;
        let hasEnzymes = false;

        for (let i = 0; i < medication.medication.length; i++) {
            if (medication.medication[i].interaction?.enzymes) {
                hasEnzymes = true;
                break;
            }
        }

        return hasEnzymes;
    };

    const isEnzymeButtonDisabled = !hasMedicationEnzymes(doctorStore.currentMedication);

    return (
        <>
            <UserNavigation
                leftComponent={
                    <BackContainer>
                        {uid && (
                            <Link to={Routes.DOCTOR.OPTIMIZE.replace(":uid", uid)}>
                                <Icon name="arrowLeft" hoverColor={Colors.secondary} />
                            </Link>
                        )}
                        <NavigationHeading>
                            {t("screen.doctor.auto_optimize.navigation.replace_automatically")}
                        </NavigationHeading>
                    </BackContainer>
                }
            />
            <StandardContainer>
                <Heading>{t("screen.doctor.auto_optimize.heading")}</Heading>
                <TextContainer>
                    <span>{t("screen.doctor.auto_optimize.text")}</span>
                    <Tooltip
                        arrow
                        placement="top"
                        title={isEnzymeButtonDisabled ? t("screen.doctor.auto_optimize.no_enzymes_available") : ""}
                    >
                        <span>
                            <Button
                                disabled={isEnzymeButtonDisabled}
                                size="small"
                                disableRipple
                                onClick={handleToggleShowDetailInfo}
                            >
                                <InfoButtonText>
                                    {showDetailInfo
                                        ? t("screen.doctor.auto_optimize.show_less_information")
                                        : t("screen.doctor.auto_optimize.show_more_information")}
                                </InfoButtonText>
                                <Icon color="currentColor" hoverColor="currentColor" name="info" />
                            </Button>
                        </span>
                    </Tooltip>
                </TextContainer>
                <StyledSection
                    heading={t("screen.doctor.auto_optimize.section.replacement_in_same_level")}
                    description={t("screen.doctor.auto_optimize.section.replacement_in_same_level.description")}
                    sectionSummary={<CombinationCount count={doctorStore.optimizerResultSameLevel.length} />}
                >
                    <Combinations
                        data={doctorStore.optimizerResultSameLevel}
                        showDetailInfo={showDetailInfo}
                        onClickSelect={handleSelectCombination}
                    />
                </StyledSection>
                {/* <Section
                    heading={t("screen.doctor.auto_optimize.section.replacement_in_different_level")}
                    description={t("screen.doctor.auto_optimize.section.replacement_in_different_level.description")}
                    sectionSummary={<CombinationCount count={differentLevelCombinations.length} />}
                >
                    <Combinations
                        data={differentLevelCombinations}
                        showDetailInfo={showDetailInfo}
                        onClickSelect={handleSelectCombination}
                    />
                </Section> */}
            </StandardContainer>
            {showNoticeDialog && (
                <ReplacementRemarksDialog
                    open={showNoticeDialog}
                    onClose={handleCloseNoticeDialog}
                    onSubmit={handleSubmitNoticeDialog}
                />
            )}
        </>
    );
});
