import { isNil } from "lodash";
import * as React from "react";
import { ReactComponent as OkBadge } from "../../assets/badges/ic_badge_check.svg";
import { ReactComponent as DangerBadge } from "../../assets/badges/ic_badge_danger.svg";
import { ReactComponent as DecreaseLowBadge } from "../../assets/badges/ic_badge_down_1.svg";
import { ReactComponent as DecreaseMediumBadge } from "../../assets/badges/ic_badge_down_2.svg";
import { ReactComponent as DecreaseHighBadge } from "../../assets/badges/ic_badge_down_3.svg";
import { ReactComponent as NotTestedBadge } from "../../assets/badges/ic_badge_not_tested.svg";
import { ReactComponent as UnknownBadge } from "../../assets/badges/ic_badge_unknown.svg";
import { ReactComponent as IncreaseLowBadge } from "../../assets/badges/ic_badge_up_1.svg";
import { ReactComponent as IncreaseMediumBadge } from "../../assets/badges/ic_badge_up_2.svg";
import { ReactComponent as IncreaseHighBadge } from "../../assets/badges/ic_badge_up_3.svg";
import { ReactComponent as WarningBadge } from "../../assets/badges/ic_badge_warning.svg";
import { ReactComponent as PoorMetabolizerBadge } from "../../assets/badges/ic_badge_pm.svg";
import { ReactComponent as UltraRapidMetabolizerBadge } from "../../assets/badges/ic_badge_ur.svg";
import { ReactComponent as GrayOkBadge } from "../../assets/badges/ic_gray_badge_check.svg";
import { ReactComponent as GrayDangerBadge } from "../../assets/badges/ic_gray_badge_danger.svg";
import { ReactComponent as GrayDecreaseLowBadge } from "../../assets/badges/ic_gray_badge_down_1.svg";
import { ReactComponent as GrayDecreaseMediumBadge } from "../../assets/badges/ic_gray_badge_down_2.svg";
import { ReactComponent as GrayDecreaseHighBadge } from "../../assets/badges/ic_gray_badge_down_3.svg";
import { ReactComponent as GrayUnknownBadge } from "../../assets/badges/ic_gray_badge_unknown.svg";
import { ReactComponent as GrayIncreaseLowBadge } from "../../assets/badges/ic_gray_badge_up_1.svg";
import { ReactComponent as GrayIncreaseMediumBadge } from "../../assets/badges/ic_gray_badge_up_2.svg";
import { ReactComponent as GrayIncreaseHighBadge } from "../../assets/badges/ic_gray_badge_up_3.svg";
import { ReactComponent as GrayWarningBadge } from "../../assets/badges/ic_gray_badge_warning.svg";
import { ReactComponent as GrayPoorMetabolizerBadge } from "../../assets/badges/ic_gray_badge_pm.svg";
import { ReactComponent as GrayUltraRapidMetabolizerBadge } from "../../assets/badges/ic_gray_badge_ur.svg";
import { ReactComponent as WhiteOkBadge } from "../../assets/badges/ic_white_badge_check.svg";
import { ReactComponent as WhiteDangerBadge } from "../../assets/badges/ic_white_badge_danger.svg";
import { ReactComponent as WhiteDecreaseLowBadge } from "../../assets/badges/ic_white_badge_down_1.svg";
import { ReactComponent as WhiteDecreaseMediumBadge } from "../../assets/badges/ic_white_badge_down_2.svg";
import { ReactComponent as WhiteDecreaseHighBadge } from "../../assets/badges/ic_white_badge_down_3.svg";
import { ReactComponent as WhiteUnknownBadge } from "../../assets/badges/ic_white_badge_unknown.svg";
import { ReactComponent as WhiteIncreaseLowBadge } from "../../assets/badges/ic_white_badge_up_1.svg";
import { ReactComponent as WhiteIncreaseMediumBadge } from "../../assets/badges/ic_white_badge_up_2.svg";
import { ReactComponent as WhiteIncreaseHighBadge } from "../../assets/badges/ic_white_badge_up_3.svg";
import { ReactComponent as WhiteWarningBadge } from "../../assets/badges/ic_white_badge_warning.svg";
import { ReactComponent as WhitePoorMetabolizerBadge } from "../../assets/badges/ic_white_badge_pm.svg";
import { ReactComponent as WhiteUltraRapidMetabolizerBadge } from "../../assets/badges/ic_white_badge_ur.svg";
import { IFCCode } from "../../types";
import { Tooltip } from "@material-ui/core";

// See details about this import method here: https://create-react-app.dev/docs/adding-images-fonts-and-files/#adding-svgs
// WARNING: Setting the title as prop doesn't work with typescript, so remove the autogenerated title tags from svg!!!

const badges = {
    normal: {
        decreaseLow: DecreaseLowBadge,
        decreaseMedium: DecreaseMediumBadge,
        decreaseHigh: DecreaseHighBadge,
        okay: OkBadge,
        increaseLow: IncreaseLowBadge,
        increaseMedium: IncreaseMediumBadge,
        increaseHigh: IncreaseHighBadge,
        danger: DangerBadge,
        warning: WarningBadge,
        unknown: UnknownBadge,
        notTested: NotTestedBadge,
        poorMetabolizer: PoorMetabolizerBadge,
        ultraRapidMetabolizer: UltraRapidMetabolizerBadge,
    },
    white: {
        decreaseLow: WhiteDecreaseLowBadge,
        decreaseMedium: WhiteDecreaseMediumBadge,
        decreaseHigh: WhiteDecreaseHighBadge,
        okay: WhiteOkBadge,
        increaseLow: WhiteIncreaseLowBadge,
        increaseMedium: WhiteIncreaseMediumBadge,
        increaseHigh: WhiteIncreaseHighBadge,
        danger: WhiteDangerBadge,
        warning: WhiteWarningBadge,
        unknown: WhiteUnknownBadge,
        notTested: NotTestedBadge,
        poorMetabolizer: WhitePoorMetabolizerBadge,
        ultraRapidMetabolizer: WhiteUltraRapidMetabolizerBadge,
    },
    gray: {
        decreaseLow: GrayDecreaseLowBadge,
        decreaseMedium: GrayDecreaseMediumBadge,
        decreaseHigh: GrayDecreaseHighBadge,
        okay: GrayOkBadge,
        increaseLow: GrayIncreaseLowBadge,
        increaseMedium: GrayIncreaseMediumBadge,
        increaseHigh: GrayIncreaseHighBadge,
        danger: GrayDangerBadge,
        warning: GrayWarningBadge,
        unknown: GrayUnknownBadge,
        notTested: NotTestedBadge,
        poorMetabolizer: GrayPoorMetabolizerBadge,
        ultraRapidMetabolizer: GrayUltraRapidMetabolizerBadge,
    },
};

const getBadgeNameByValue = (value: IFCCode, isEnzyme: boolean): StringValue | undefined => {
    switch (value) {
        case 1:
        case 11: {
            return isEnzyme ? "ultraRapidMetabolizer" : "danger";
        }
        case 2:
        case 10: {
            return isEnzyme ? "poorMetabolizer" : "danger";
        }
        case 999: {
            return "danger";
        }
        case 5:
        case 12: {
            return "okay";
        }
        case 6: {
            return "decreaseLow";
        }
        case 7: {
            return "increaseLow";
        }
        case 3: {
            return "increaseMedium";
        }
        case 4: {
            return "decreaseMedium";
        }
        case 8: {
            return "decreaseHigh";
        }
        case 9: {
            return "increaseHigh";
        }
        case 997: {
            return "notTested";
        }
        case 998: {
            return "unknown";
        }
    }
};

type StringValue = keyof typeof badges["normal"] & keyof typeof badges["white"] & keyof typeof badges["gray"];

export type IBadgeValue = IFCCode | StringValue;
export type IBadgeColors = "normal" | "white" | "gray";

type IProps = {
    value?: IBadgeValue;
    size?: "small" | "large";
    color?: IBadgeColors;
    width?: number;
    height?: number;
    style?: React.CSSProperties;
    tooltipText?: string;
    isEnzyme?: boolean;
};

const getHeight = (size: IProps["size"]) => {
    switch (size) {
        case "small": {
            return 16;
        }
        case "large":
        default: {
            return 28;
        }
    }
};

export const Badge = ({ value, size = "large", color = "normal", width, height, style, tooltipText, isEnzyme = false }: IProps) => {
    if (isNil(value)) {
        return null;
    }

    const name = typeof value === "number" ? getBadgeNameByValue(value, isEnzyme) : value;
    const standardHeight = getHeight(size);

    const BadgeComponent = name ? badges[color][name] : null;

    if (BadgeComponent && tooltipText) {
        return (
            <Tooltip title={tooltipText} aria-label={tooltipText}>
                <BadgeComponent
                    width={width}
                    height={height || standardHeight}
                    style={{ display: "block", ...style }}
                />
            </Tooltip>
        );
    }

    return BadgeComponent ? (
        <BadgeComponent width={width} height={height || standardHeight} style={{ display: "block", ...style }} />
    ) : null;
};
