import { InputAdornment, TextField } from "@material-ui/core";
import * as React from "react";
import { Colors } from "../util/Colors";
import { Icon } from "../util/Icon";

type IProps = {
    value?: string;
    onChange: (value: string) => void;
    placeholder?: string;
    "data-id"?: string;
    style?: React.CSSProperties;
};

export const Searchbar = ({ value, onChange, placeholder, "data-id": dataId, style }: IProps) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    const handleReset = () => {
        onChange("");
    };

    return (
        <TextField
            data-id={dataId}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder={placeholder}
            autoComplete="off"
            value={value}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {value ? (
                            <Icon name="x" hoverColor={Colors.secondary} onClick={handleReset} />
                        ) : (
                            <Icon name="search" />
                        )}
                    </InputAdornment>
                ),
            }}
            style={style}
        />
    );
};
