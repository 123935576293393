import { flatMap } from "lodash";
import { optimizerStore } from "../../stores/OptimizerStore";
import { IPatient, ISubstanceId } from "../../types";

export const getTestedGroups = (patient: IPatient) =>
    (patient.singleMedGroups || []).reduce<{ [groupName: string]: ISubstanceId[] }>(
        (accumulator, groupName) => ({
            ...accumulator,
            [groupName]: optimizerStore.substances?.singleMedGroups[groupName] || [],
        }),
        {},
    );

export const hasMedicationCheck = (patient: IPatient) => {
    const testedGroups = getTestedGroups(patient);

    return flatMap(Object.keys(testedGroups), group => testedGroups[group]).length > 1;
};

export const getPatientLink = (route: string, searchQuery: string) =>
    `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ""
    }${route}?search=${searchQuery}`;
