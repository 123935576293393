import { Field, Form, Formik } from "formik";
import * as React from "react";
import * as Yup from "yup";
import { CustomInputField } from "../ui/CustomInputField";
import { t } from "../../i18n/util";
import { Button } from "@material-ui/core";
import styled from "styled-components";
import { Colors } from "../util/Colors";

const zxcvbn = require("zxcvbn");

const MessageContainer = styled.div.attrs((props: any) => ({
    success: props.success,
}))`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    color: ${props => (props.success ? Colors.okay : Colors.danger)};
`;

export type ISetPasswordValues = {
    password: string;
    passwordConfirm: string;
};

type IProps = {
    success: boolean;
    message: string;
    onSubmit: (model: ISetPasswordValues) => void;
};

export const SetPasswordForm = ({ success, message, onSubmit }: IProps) => (
    <Formik
        initialValues={{
            password: "",
            passwordConfirm: "",
        }}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
            password: Yup.string()
                .test("has-correct-strength", t("screen.set_password.form.password.validation_error"), function(value) {
                    return zxcvbn(value).score >= 3;
                })
                .min(8, t("screen.set_password.form.password.validation_error"))
                .required(t("screen.set_password.form.password.validation_error")),
            passwordConfirm: Yup.string()

                .oneOf([Yup.ref("password"), null], t("screen.set_password.form.password.confirm.validation_error")) // Compare 2 fields: https://github.com/formium/formik/issues/90
                .required(t("screen.set_password.form.password.confirm.required.validation_error")),
        })}
        validateOnBlur
        validateOnChange
    >
        {({ errors, touched, isSubmitting, isValid }) => (
            <Form>
                <Field
                    data-id="set_password_password"
                    component={CustomInputField}
                    label={t("screen.set_password.form.password.label")}
                    name="password"
                    type="password"
                    showValidationErrorText={true}
                    errorMessage={errors.password}
                    showPasswordChecker={true}
                    isTouched={touched.password}
                />

                <Field
                    data-id="set_password_password_confirm"
                    component={CustomInputField}
                    label={t("screen.set_password.form.password.confirm.label")}
                    name="passwordConfirm"
                    type="password"
                    errorMessage={errors.passwordConfirm}
                    isTouched={touched.passwordConfirm}
                />
                <MessageContainer success={success}>{!!message && message}</MessageContainer>
                <Button
                    data-id="set_password_submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!isValid || isSubmitting || success}
                    type="submit"
                    size="large"
                >
                    {t("screen.set_password.form.submit")}
                </Button>
            </Form>
        )}
    </Formik>
);
