import React from "react";
import Select, { SelectProps } from "@material-ui/core/Select";
import { FieldInputProps } from "formik";
import { createStyles, FormControl, InputLabel, makeStyles, MenuItem, Theme } from "@material-ui/core";
import { ISelectOption } from "../../types";
import { Colors } from "../util/Colors";

type IProps = SelectProps & {
    errorMessage?: string;
    field: FieldInputProps<string>;
    isTouched?: boolean;
    showValidationErrorText: boolean;
    "data-id"?: string;
    options: ISelectOption[];
};

export const CustomSelect = ({
    style,
    options,
    required,
    label,
    "aria-label": ariaLabel,
    value,
    name,
    field,
    showValidationErrorText,
    errorMessage,
    isTouched,
    "data-id": dataId,
}: IProps) => {
    const showError = isTouched && !!errorMessage;

    const SelectOptions = options
        ? options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                  {option.text}
              </MenuItem>
          ))
        : [];
    return (
        <div style={style}>
            <FormControl variant="outlined" disabled={!SelectOptions.length} fullWidth>
                <InputLabel error={showError} id={`${field.name}-select-label`}>
                    {label}
                </InputLabel>
                <Select
                    error={showError}
                    labelId={`${field.name}-select-label`}
                    label={label}
                    value={field.value || ""}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    name={field.name}
                >
                    {SelectOptions}
                </Select>
            </FormControl>
            {showValidationErrorText && (
                <span
                    data-id={`${dataId}_error`}
                    style={{
                        color: Colors.danger,
                        display: "block",
                        minHeight: 18,
                        width: "100%",
                        marginTop: 4,
                        marginBottom: 10,
                        fontSize: 14,
                    }}
                >
                    {showError && errorMessage}
                </span>
            )}
        </div>
    );
};
