import { PrimitiveType, FormatXMLElementFn } from "intl-messageformat";
import { createIntl, createIntlCache } from "react-intl";
import { german } from "./de";
import { english } from "./en";
import { italian } from "./it";
import { generalStore } from "../stores/GeneralStore";
import { ILocales, LOCALES } from "./ILocales";
import { authStore } from "../stores/AuthStore";

const DEFAULT_LOCALE = LOCALES.de;

type IMessages = {
    [locale in ILocales]: {
        [message: string]: string;
    };
};

const intlMessages: IMessages = {
    [LOCALES.en]: english,
    [LOCALES.de]: german,
    [LOCALES.it]: italian,
};

const cache = createIntlCache();

let intl = createIntl(
    {
        locale: DEFAULT_LOCALE,
        messages: intlMessages[DEFAULT_LOCALE],
    },
    cache,
);

export type IMessageIDS = keyof typeof english;

const setLocale = (locale: ILocales) => {
    if (generalStore.locale === locale) {
        return;
    }

    intl = createIntl(
        {
            locale: locale,
            messages: intlMessages[locale],
        },
        cache,
    );

    generalStore.locale = locale;

    if (authStore.authProfile) {
        authStore.authProfile.language = locale;
    }

    console.log(`%cSet locale to "${locale}".`, "background: #eee; color: #666;");
};

const t = (messageId: IMessageIDS, values?: Record<string, PrimitiveType>) => {
    return intl.formatMessage({ id: messageId }, values);
};

const tHtml = (
    messageId: IMessageIDS,
    values?: Record<string, PrimitiveType | React.ReactElement | FormatXMLElementFn>,
) => {
    return intl.formatMessage({ id: messageId }, values);
};

export { DEFAULT_LOCALE, intl, intlMessages, setLocale, t, tHtml };
