import { observable, action } from "mobx";
import { API, isUnauthorizedError } from "../network/API";
import { IDoctor } from "../types";
import { generalStore } from "./GeneralStore";
import { t } from "../i18n/util";

class PgxAdminStore {
    @observable doctors: IDoctor[] | null = null;

    @action wipe() {
        this.doctors = null;
    }

    @action fetchDoctors = async () => {
        try {
            const { results } = await API.getDoctors();

            this.doctors = results;
        } catch (error) {
            if (!isUnauthorizedError(error)) {
                generalStore.errorMessage = t("error.loadDoctorsList");
            }
            console.error(error);
        }
    };
}

const pgxAdminStore = new PgxAdminStore();

export { pgxAdminStore };
