import { Button } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { t } from "../../../../i18n/util";
import { API } from "../../../../network/API";
import { IGetNewsOptions, IGetNewsResponse, INewsValues } from "../../../../types";
import { CustomPagination } from "../../../ui/CustomPagination";
import { ConfirmDeleteNewsDialog } from "./ConfirmDeleteNewsDialog";
import { NewsModal } from "./NewsModal";
import { NewsTable } from "./NewsTables";

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const ROWS_PER_PAGE = 5;

export const News = () => {
    const [isEditNewsModalOpen, setIsEditNewsModalOpen] = React.useState(false);
    const [newsResponse, setNewsResponse] = React.useState<IGetNewsResponse>();
    const [selectedNews, setSelectedNews] = React.useState<INewsValues>();
    const [currentPage, setCurrentPage] = React.useState(1);
    const [orderDirection, setOrderDirection] = React.useState<"asc" | "desc">("desc");
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);

    const handleOpenEditNewsModal = () => {
        setIsEditNewsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsEditNewsModalOpen(false);
        setTimeout(() => {
            setSelectedNews(undefined);
        }, 500);
    };

    const handlelickEditNews = (newsValues: INewsValues) => {
        setSelectedNews(newsValues);
        setIsEditNewsModalOpen(true);
    };

    const handleChangeSort = (direction: "asc" | "desc") => {
        const options: IGetNewsOptions = { order: direction };
        setOrderDirection(direction);
        setCurrentPage(1);
        loadNews(options);
    };

    const loadNews = React.useCallback(async (options: IGetNewsOptions = {}) => {
        console.log(options);

        try {
            const newsResponse = await API.getNews({ ...options, limit: ROWS_PER_PAGE });
            setNewsResponse(newsResponse);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const handleAddNewsSuccess = () => {
        setIsEditNewsModalOpen(false);
        loadNews();
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        loadNews({ order: orderDirection, offset: ROWS_PER_PAGE * (page - 1) });
    };

    const handleOpenDeleteDialog = (newsValues: INewsValues) => {
        setIsDeleteDialogOpen(true);
        setSelectedNews(newsValues);
    };

    const handleCloseDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
        setTimeout(() => {
            setSelectedNews(undefined);
        }, 500);
    };

    const handleSubmitDeleteDialog = async () => {
        if (selectedNews) {
            try {
                await API.deleteNews(selectedNews.uid as string);
                handleCloseDeleteDialog();
                loadNews();
            } catch (error) {
                console.log(error);
            }
        }
    };

    React.useEffect(() => {
        loadNews();
    }, [loadNews]);

    return (
        <>
            <NewsModal
                isOpen={isEditNewsModalOpen}
                onClose={handleCloseModal}
                onSuccess={handleAddNewsSuccess}
                defaultValues={selectedNews}
            />
            <ConfirmDeleteNewsDialog
                isOpen={isDeleteDialogOpen}
                action={handleSubmitDeleteDialog}
                onClose={handleCloseDeleteDialog}
            />
            <ButtonContainer>
                <Button
                    data-id="add_news"
                    color="secondary"
                    variant="contained"
                    style={{
                        minWidth: 150,
                    }}
                    href=""
                    onClick={handleOpenEditNewsModal}
                >
                    {t("screen.pgx_admin.news.add_news")}
                </Button>
            </ButtonContainer>

            {newsResponse !== null && (
                <>
                    <NewsTable
                        rows={newsResponse?.results as INewsValues[]}
                        onChangeSort={handleChangeSort}
                        onClickEditNews={handlelickEditNews}
                        onClickDeleteNews={handleOpenDeleteDialog}
                        orderDirection={orderDirection}
                    />
                </>
            )}
            {newsResponse !== null && (newsResponse?.total as number) > ROWS_PER_PAGE && (
                <>
                    <CustomPagination
                        data-id="pagination"
                        count={newsResponse?.total as number}
                        rowsPerPage={ROWS_PER_PAGE}
                        page={currentPage}
                        onChangePage={handlePageChange}
                    />
                </>
            )}
        </>
    );
};
