import { Button } from "@material-ui/core";
import * as React from "react";
import styled from "styled-components";
import { t } from "../../../../i18n/util";
import { ImageEmpty } from "../../../util/Images";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 45px;
`;

const StyledImageEmpty = styled(ImageEmpty)`
    margin-bottom: 16px;
`;

const Heading = styled.div`
    width: 285px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 16px;
`;

const HintText = styled.div`
    width: 375px;
    font-size: 14px;
    text-align: center;
    color: #878787;
    margin-bottom: 25px;
`;

export const EmptyHint = () => (
    <Container data-id="empty_list_hint">
        <StyledImageEmpty />
        <Heading>{t("screen.doctor.patients.empty_list.heading")}</Heading>
        <HintText>{t("screen.doctor.patients.empty_list.text")}</HintText>
        <Button
            data-id="pharmgenetix_info"
            variant="contained"
            color="primary"
            href="http://www.pharmgenetix.com"
            target="_blank"
            style={{
                width: 270,
            }}
        >
            {t("screen.doctor.patients.empty_list.pharmgenetix_info")}
        </Button>
    </Container>
);
