import * as React from "react";
import styled from "styled-components";
import { t } from "../../i18n/util";

const zxcvbn = require("zxcvbn");

enum ESecurityColors {
    weak = "#FB5607",
    medium = "#FFBE0B",
    strong = "#28B6A6",
}

type IProps = {
    password: string;
};

type IProgress = {
    width?: string;
};

type IBar = {
    hasOpacity?: boolean;
};

const Bar = styled.div<IBar>`
    position: absolute;
    top: 0;
    left: 0;
    opacity: ${props => (props.hasOpacity ? 0.5 : 1)};
    background: linear-gradient(
        to right,
        ${ESecurityColors.weak} 0,
        ${ESecurityColors.weak} 33%,
        ${ESecurityColors.medium} 33%,
        ${ESecurityColors.medium} 66%,
        ${ESecurityColors.strong} 66%,
        ${ESecurityColors.strong} 100%
    );
    width: 216px;
    height: 4px;
`;

const Wrapper = styled.div`
    position: relative;
    width: 216px;
    margin-right: 10px;
`;

const Progress = styled.div<IProgress>`
    position: relative;
    width: ${props => props.width || "33.3333%"};
    transition: width 1s;
    overflow: hidden;
    height: 4px;
`;

const getProgressWidth = (score: number) => {
    switch (score) {
        case 3:
        case 4:
            return "100%";
        case 2:
            return "66%";
        default:
            return "33%";
    }
};

const StrengthText = styled.p`
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    color: #878787;
`;

const getStrengthText = (score: number) => {
    switch (score) {
        case 3:
        case 4:
            return t("password_strength_checker.strong_password");
        case 2:
            return t("password_strength_checker.medium_password");
        default:
            return t("password_strength_checker.weak_password");
    }
};

const getImprovementOptions = (password: string): string[] => {
    const improvements: string[] = [];

    if (!password.match(/[a-z]/g)) {
        improvements.push(t("password_strength_checker.lower_case_letter"));
    }

    if (!password.match(/[A-Z]/g)) {
        improvements.push(t("password_strength_checker.upper_case_letter"));
    }

    if (!password.match(/\W/g)) {
        improvements.push(t("password_strength_checker.special_character"));
    }

    return improvements;
};

const getImprovementInfo = (password: string) => {
    const improvements = getImprovementOptions(password);

    if (!password || !improvements.length) {
        return "";
    }

    let text = "";
    text += improvements.length ? `${t("password_strength_checker.info_text_begin")} ` : "";
    text += improvements.join(", ");
    text += ` ${t("password_strength_checker.info_text_end")} `;
    text += password.length < 8 ? `${t("password_strength_checker.password_too_short")}` : "";

    return text;
};

export const PasswordStrengthChecker = ({ password }: IProps) => {
    const score: number = zxcvbn(password).score;

    const text = getImprovementInfo(password);
    return (
        <>
            <div style={{ display: "flex", alignItems: "center", marginTop: 10, height: 20 }}>
                <Wrapper>
                    <Progress width={password ? getProgressWidth(score) : "0%"}>
                        <Bar />
                    </Progress>
                    <Bar hasOpacity />
                </Wrapper>
                <StrengthText>{!!password ? getStrengthText(score) : " "}</StrengthText>
            </div>
            <StrengthText>
                <strong>{text}</strong>
            </StrengthText>
        </>
    );
};
