import * as React from "react";
import styled from "styled-components";

type IProps = {
    visible?: boolean;
};

const VerticalLine = styled.div`
    width: 2px;
    background-color: #e1e1e1;
    padding: 30px 0px 20px 0px;
    visibility: ${({ visible }: IProps) => (visible ? "visible" : "hidden")};
`;

export const VerticalSeparator = ({ visible = true }: IProps) => <VerticalLine visible={visible}>&nbsp;</VerticalLine>;
