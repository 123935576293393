import * as React from "react";
import { withStyles, Tab } from "@material-ui/core";
import { Colors } from "../util/Colors";
import { Link } from "react-router-dom";

const TOOLBAR_HEIGHT = 70;

type INavigationTabProps = {
    label: string;
    to: string;
    onClick?: React.EventHandler<any>;
};

export const NavigationTab = withStyles({
    root: {
        height: TOOLBAR_HEIGHT,
        fontWeight: "bold",
        textTransform: "none",
        minWidth: 0,
        padding: 0,
        marginRight: 60,
        fontSize: 16,
        color: "#414141",
        "&:hover": {
            textDecoration: "none",
        },
    },
    selected: {
        color: Colors.primary,
    },
})((props: INavigationTabProps) => <Tab disableRipple component={Link} {...props} />);
