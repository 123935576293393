import { Dialog, DialogTitle, DialogContent, Button, DialogActions, Select } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import React from "react";
import { t } from "../../i18n/util";
import { CustomInputField } from "./CustomInputField";
import * as Yup from "yup";
import { CustomSelect } from "./CustomSelect";
import { ISelectOption } from "../../types";
import { API } from "../../network/API";
import { isEmail, isPhone } from "../util/Helper";
import styled from "styled-components";

type IProps = {
    uid: string;
    isOpen: boolean;
    onClose: () => void;
};

const Description = styled.p`
    margin-bottom: 20px;
`;

const FormGroupHeadline = styled.strong`
    margin-bottom: 15px;
    display: inline-block;
`;

const FormGroup = styled.div`
    display: flex;
`;

export const AddDoctorDialog = ({ isOpen, onClose, uid }: IProps) => {
    const onSubmit = async (values: any) => {
        if (values) {
            try {
                const result = await API.postDoctorSuggestion(values, uid);

                if (result && result.success) {
                    onClose();
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const contactTypeOptions: ISelectOption[] = [
        { text: t("add_doctor_dialog.label.email"), value: "email" },
        { text: t("add_doctor_dialog.label.phone"), value: "phone" },
    ];

    return (
        <Dialog open={isOpen} fullWidth maxWidth="sm">
            <DialogTitle id="alert-dialog-title">{t("add_doctor_dialog.headline")}</DialogTitle>
            <DialogContent>
                <Description>{t("add_doctor_dialog.description")}</Description>
                <Formik
                    initialValues={{
                        firstname: "",
                        lastname: "",
                        district: "",
                        contactType: "phone",
                        contactData: "",
                    }}
                    onSubmit={onSubmit}
                    validationSchema={Yup.object().shape({
                        firstname: Yup.string().required(t("add_doctor_dialog.form.firstname_error")),
                        lastname: Yup.string().required(t("add_doctor_dialog.form.lastname_error")),
                        district: Yup.string().required(t("add_doctor_dialog.form.district_error")),
                        contactType: Yup.string().required(),
                        contactData: Yup.string()
                            .when("contactType", (value: string) => {
                                if (value === "phone") {
                                    return Yup.string().test(
                                        "is valid date",
                                        t("add_doctor_dialog.form.phone_error"),
                                        function(value: string) {
                                            return isPhone(value);
                                        },
                                    );
                                } else {
                                    return Yup.string().test(
                                        "is valid date",
                                        t("add_doctor_dialog.form.mail_error"),
                                        function(value: string) {
                                            return isEmail(value);
                                        },
                                    );
                                }
                            })
                            .required(),
                    })}
                    validateOnBlur={false}
                    validateOnChange={true}
                >
                    {({ errors, touched, values, isValid }) => (
                        <Form>
                            <FormGroupHeadline>{t("add_doctor_dialog.form_group.data.text")}</FormGroupHeadline>
                            <Field
                                data-id="add_doctor_firstname"
                                component={CustomInputField}
                                label={t("add_doctor_dialog.label.firstname")}
                                name="firstname"
                                type="text"
                                required
                                autoComplete="firstname"
                                isTouched={touched.firstname}
                                errorMessage={errors.firstname}
                                showValidationErrorText={true}
                                style={{ marginBottom: 32 }}
                            />
                            <Field
                                data-id="add_doctor_lastname"
                                component={CustomInputField}
                                label={t("add_doctor_dialog.label.lastname")}
                                name="lastname"
                                type="text"
                                errorMessage={errors.lastname}
                                required
                                autoComplete="firstname"
                                isTouched={touched.lastname}
                                showValidationErrorText={true}
                                style={{ marginBottom: 32 }}
                            />
                            <Field
                                data-id="add_doctor_district"
                                component={CustomInputField}
                                label={t("add_doctor_dialog.label.district")}
                                name="district"
                                type="text"
                                required
                                errorMessage={errors.district}
                                autoComplete="district"
                                isTouched={touched.district}
                                showValidationErrorText={true}
                                style={{ marginBottom: 32 }}
                            />
                            <FormGroupHeadline>{t("add_doctor_dialog.form_group.contact_data.text")}</FormGroupHeadline>
                            <FormGroup>
                                <Field
                                    data-id="add_doctor_contact_type"
                                    component={CustomSelect}
                                    options={contactTypeOptions}
                                    label={t("add_doctor_dialog.label.contact_type")}
                                    name="contactType"
                                    value="phone"
                                    errorMessage={errors.contactType}
                                    type="text"
                                    required
                                    autoComplete="contactType"
                                    isTouched={touched.contactType}
                                    showValidationErrorText={true}
                                    style={{ marginBottom: 32, flexGrow: 2, marginRight: 15 }}
                                />
                                <Field
                                    data-id="add_doctor_contact_data"
                                    component={CustomInputField}
                                    label={
                                        values.contactType === "email"
                                            ? t("add_doctor_dialog.label.email")
                                            : t("add_doctor_dialog.label.phone")
                                    }
                                    name="contactData"
                                    type="text"
                                    required
                                    autoComplete="contactData"
                                    isTouched={touched.contactData}
                                    errorMessage={errors.contactData}
                                    showValidationErrorText={true}
                                    style={{ marginBottom: 32, flexGrow: 4 }}
                                />
                            </FormGroup>
                            <DialogActions>
                                <Button onClick={onClose} color="primary">
                                    {t("confirm_resend_dialog.cancel.label")}
                                </Button>
                                <Button disabled={!isValid} type="submit" variant="contained" color="primary" autoFocus>
                                    {t("add_doctor_dialog.form.submit.text")}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};
