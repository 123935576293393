export const fetchFile = (file: Blob, forceDownload?: boolean, fileName?: string) => {
    // https://stackoverflow.com/questions/32545632/how-can-i-download-a-file-using-window-fetch
    const url = window.URL.createObjectURL(file);
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.type = file.type;
    if (forceDownload && fileName) {
        a.download = fileName;
    }
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    const event = document.createEvent("MouseEvents");
    event.initEvent("click", true, true);
    a.dispatchEvent(event);
    // Alternative way to create the event:
    // const event = new MouseEvent("click", { bubbles: true, cancelable: true });
    // a.dispatchEvent(event);
    a.remove(); //afterwards we remove the element again
    // URL.revokeObjectURL(url);
};
