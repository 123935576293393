import * as React from "react";
import styled from "styled-components";
import { ImageLoading } from "../util/Images";
import { t } from "../../i18n/util";

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;
    width: 160px;
`;

const PulseLoading = styled(ImageLoading)`
    margin-bottom: 24px;
    animation: pulse 2.4s ease-out infinite;
    @keyframes pulse {
        0% {
            height: 140px;
            width: 140px;
            opacity: 0.7;
        }
        50% {
            height: 160px;
            width: 160px;
            opacity: 1;
        }
        100% {
            height: 140px;
            width: 140px;
            opacity: 0.7;
        }
    }
`;

const Heading = styled.h3`
    margin-bottom: 16px;
`;

const Text = styled.div`
    font-size: 14px;
`;

export const OptimizeLoadingScreen = () => (
    <Container>
        <ImageContainer>
            <PulseLoading />
        </ImageContainer>
        <Heading>{t("loadingscreen.heading")}</Heading>
        <Text>{t("loadingscreen.info_text")}</Text>
    </Container>
);
