import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    withStyles,
} from "@material-ui/core";
import React from "react";
import { t } from "../../i18n/util";
import { Colors } from "../util/Colors";
import { Icon } from "../util/Icon";

type IProps = {
    open: boolean;
    onSubmit: () => void;
    onClose?: () => void;
    buttonText?: string;
    disableButton?: boolean;
    title?: string;
    message: React.ReactNode;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
};

const StyledDialog = withStyles({
    paper: {
        padding: 40,
        "& > *": {
            padding: 0,
        },
    },
})(Dialog);

export const CustomDialog = ({
    open,
    onSubmit,
    onClose,
    title,
    message,
    buttonText,
    disableButton,
    maxWidth = "sm",
}: IProps) => (
    <StyledDialog
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={maxWidth}
        disableBackdropClick
    >
        {(title || onClose) && (
            <DialogTitle disableTypography id="form-dialog-title" style={{ position: "relative", marginBottom: 24 }}>
                {title && <h3>{title}</h3>}
                {onClose && (
                    <Icon
                        name="x"
                        data-id="dialog_close_button"
                        onClick={onClose}
                        hoverColor={Colors.secondary}
                        style={{ position: "absolute", top: 0, right: 0 }}
                    />
                )}
            </DialogTitle>
        )}
        <DialogContent style={{ marginBottom: 32 }}>
            <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
            {onClose && (
                <Button onClick={onClose} color="primary">
                    {t("dialog.button_cancel")}
                </Button>
            )}
            <Button onClick={onSubmit} variant="contained" color="primary" disabled={disableButton}>
                {buttonText ? buttonText : t("dialog.button_next")}
            </Button>
        </DialogActions>
    </StyledDialog>
);
