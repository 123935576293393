import { Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles, TextField } from "@material-ui/core";
import * as React from "react";
import { t } from "../../i18n/util";
import styled from "styled-components";
import { Icon } from "../util/Icon";
import { Colors } from "../util/Colors";

const ContentText = styled.div`
    margin-bottom: 30px;
`;

type IProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: (notice: string) => void;
};

export const ReplacementRemarksDialog = ({ open, onClose, onSubmit }: IProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [notice, setNotice] = React.useState("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotice(event.target.value);
    };

    const handleSubmitWithNotice = () => {
        setIsSubmitting(true);
        onSubmit(notice || "");
    };

    const handleSubmitWithoutNotice = () => {
        setIsSubmitting(true);
        onSubmit("");
    };

    return (
        <Dialog
            PaperProps={{ "data-id": "replacement_notice_dialog" } as any}
            open={open}
            onClose={onClose}
            aria-labelledby="dialog-title"
            fullWidth
            disableBackdropClick
            maxWidth="md"
        >
            <DialogTitle disableTypography id="dialog-title" style={{ position: "relative" }}>
                <h3>{t("screen.doctor.replacement_notice.dialog.title")}</h3>
                <Icon
                    name="x"
                    data-id="dialog_close_button"
                    onClick={onClose}
                    hoverColor={Colors.secondary}
                    style={{ position: "absolute", top: 0, right: 0 }}
                />
            </DialogTitle>
            <DialogContent>
                <ContentText>{t("screen.doctor.replacement_notice.dialog.text")}</ContentText>
                <TextField
                    autoFocus
                    multiline
                    rows={9}
                    variant="outlined"
                    placeholder={t("screen.doctor.replacement_notice.dialog.placeholder")}
                    type="text"
                    fullWidth
                    value={notice}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button data-id="dialog_submit_without_notice" onClick={handleSubmitWithoutNotice}>
                    {t("screen.doctor.replacement_notice.dialog.submit_without_notice")}
                </Button>
                <Button
                    data-id="dialog_submit_with_notice"
                    onClick={handleSubmitWithNotice}
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                >
                    {t("screen.doctor.replacement_notice.dialog.submit_with_notice")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
