import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Field, FormikErrors, FormikTouched } from "formik";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { IMessageIDS, intlMessages, t } from "../../i18n/util";
import { pgxAdminStore } from "../../stores/PgxAdminStore";
import { CustomDatePicker } from "../ui/CustomDatePicker";
import { CustomDropzone } from "../ui/CustomDropzone";
import { CustomInputField } from "../ui/CustomInputField";
import { omit } from "lodash";
import moment, { Moment } from "moment";
import { CustomAutocompleteMultiSelect } from "../ui/CustomAutocompleteMultiSelect";
import { CustomSelect } from "../ui/CustomSelect";
import { generalStore } from "../../stores/GeneralStore";
import { ILocales } from "../../i18n/ILocales";

const HintText = styled.div`
    font-size: 14px;
    color: #878787;
`;

type IBasePatientValues = {
    doctorUid: string[];
    firstname: string;
    lastname: string;
    language: ILocales;
    pharmgenetixId: string;
    username: string | null;
};

export type IInternalPatientValues = IBasePatientValues & {
    birthdate: MaterialUiPickersDate;
    pgxFile: File | null;
    pdfFile: File | null;
};

export type IPatientValues = IBasePatientValues & {
    birthdate: string | null;
    pgxFile: File | null;
    pdfFile: File | null;
    doctorUid?: string[];
};

export type IInitialPatientValues = IBasePatientValues & {
    birthdate: string;
    originalPgxName: string;
    originalPdfName: string;
};

type ComponentProps<Values> = {
    errors: FormikErrors<Values>;
    touched: FormikTouched<Values>;
};

export const getPatientFormPages = (initialValues?: IInitialPatientValues) => {
    const heading = initialValues
        ? `${initialValues.lastname} ${initialValues.firstname}`
        : t("screen.pgx_admin.add_edit_patient.dialog.title");

    return {
        initialValues: initialValues
            ? ({
                  ...omit(initialValues, ["originalPgxName", "originalPdfName", "birthdate"]),
                  birthdate: moment(initialValues.birthdate),
                  pgxFile: null,
                  pdfFile: null,
              } as IInternalPatientValues)
            : ({
                  doctorUid: [],
                  firstname: "",
                  lastname: "",
                  language: generalStore.locale,
                  pharmgenetixId: "",
                  birthdate: null,
                  username: "",
                  pgxFile: null,
                  pdfFile: null,
              } as IInternalPatientValues),
        pages: [
            {
                heading,
                contentText: t("screen.pgx_admin.add_edit_patient.dialog.responsible_doctor.text"),
                validationSchema: Yup.object().shape({
                    doctorUid: Yup.array().required(
                        t("screen.pgx_admin.add_edit_patient.dialog.form.doctor.validation_error"),
                    ),
                }),
                component: ({ errors, touched }: ComponentProps<IInternalPatientValues>) => (
                    <>
                        <Field
                            data-id="add_edit_patient_doctor"
                            component={CustomAutocompleteMultiSelect}
                            options={pgxAdminStore.doctors?.map(doctor => ({
                                value: doctor.uid,
                                label: `${doctor.lastname} ${doctor.firstname}`,
                            }))}
                            label={t("screen.pgx_admin.add_edit_patient.dialog.form.doctor.label")}
                            name="doctorUid"
                            type="select"
                            required
                            errorMessage={errors.doctorUid}
                            isTouched={touched.doctorUid}
                        />
                        <HintText>{t("screen.pgx_admin.add_edit_patient.dialog.form.doctor.helper_text")}</HintText>
                    </>
                ),
            },
            {
                heading,
                contentText: t("screen.pgx_admin.add_edit_patient.dialog.personal_details.text"),
                validationSchema: Yup.object().shape({
                    firstname: Yup.string()
                        .required(t("screen.pgx_admin.add_edit_patient.dialog.form.firstname.validation_error"))
                        .trim(),
                    lastname: Yup.string()
                        .required(t("screen.pgx_admin.add_edit_patient.dialog.form.lastname.validation_error"))
                        .trim(),
                    pharmgenetixId: Yup.string()
                        .required(t("screen.pgx_admin.add_edit_patient.dialog.form.pharmgenetix_id.validation_error"))
                        .trim(),
                    birthdate: Yup.object()
                        .nullable()
                        .test(
                            "is valid date",
                            t("screen.pgx_admin.add_edit_patient.dialog.form.birthdate.validation_empty_error"),
                            function(value: Moment) {
                                return value ? value.isValid() : true;
                            },
                        )
                        .required(t("screen.pgx_admin.add_edit_patient.dialog.form.birthdate.validation_empty_error")),
                }),
                component: ({ errors, touched }: ComponentProps<IInternalPatientValues>) => (
                    <>
                        <Field
                            data-id="add_edit_patient_firstname"
                            component={CustomInputField}
                            label={t("screen.pgx_admin.add_edit_patient.dialog.form.firstname.label")}
                            name="firstname"
                            type="text"
                            required
                            errorMessage={errors.firstname}
                            isTouched={touched.firstname}
                        />
                        <Field
                            data-id="add_edit_patient_lastname"
                            component={CustomInputField}
                            label={t("screen.pgx_admin.add_edit_patient.dialog.form.lastname.label")}
                            name="lastname"
                            type="text"
                            required
                            errorMessage={errors.lastname}
                            isTouched={touched.lastname}
                        />
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Field
                                data-id="add_edit_patient_pharmgenetix_id"
                                component={CustomInputField}
                                label={t("screen.pgx_admin.add_edit_patient.dialog.form.pharmgenetix_id.label")}
                                name="pharmgenetixId"
                                type="text"
                                required
                                errorMessage={errors.pharmgenetixId}
                                isTouched={touched.pharmgenetixId}
                                style={{ width: "100%", marginRight: 8 }}
                            />
                            <Field
                                data-id="add_edit_patient_birthdate"
                                component={CustomDatePicker}
                                label={t("screen.pgx_admin.add_edit_patient.dialog.form.birthdate.label")}
                                name="birthdate"
                                type="date"
                                required
                                showValidationErrorText
                                errorMessage={errors.birthdate}
                                isTouched={touched.birthdate}
                                style={{ width: "100%", margin: "0px 8px" }}
                            />
                            <Field
                                data-id="add_edit_patient_language"
                                component={CustomSelect}
                                label={t("screen.pgx_admin.add_edit_patient.dialog.form.language")}
                                name="language"
                                type="text"
                                errorMessage={errors.language}
                                isTouched={touched.language}
                                style={{ width: "100%", marginLeft: 8 }}
                                options={Object.keys(intlMessages).map(lang => ({
                                    text: t(`language.${lang}` as IMessageIDS),
                                    value: lang,
                                }))}
                            />
                        </div>
                    </>
                ),
            },
            {
                heading,
                contentText: t("screen.pgx_admin.add_edit_patient.dialog.contact_details.text"),
                validationSchema: Yup.object().shape({
                    username: Yup.string()
                        .nullable()
                        .email(t("screen.pgx_admin.add_edit_patient.dialog.form.email.validation_error"))
                        .trim(),
                }),
                component: ({ errors, touched }: ComponentProps<IInternalPatientValues>) => (
                    <>
                        <Field
                            data-id="add_edit_patient_email"
                            component={CustomInputField}
                            label={t("screen.pgx_admin.add_edit_patient.dialog.form.email.label")}
                            name="username"
                            type="email"
                            errorMessage={errors.username}
                            isTouched={touched.username}
                        />
                        <HintText>{t("screen.pgx_admin.add_edit_patient.dialog.form.email.helper_text")}</HintText>
                    </>
                ),
            },
            {
                heading,
                validationSchema: initialValues
                    ? undefined
                    : Yup.object().shape({
                          pgxFile: Yup.mixed().required(
                              t("screen.pgx_admin.add_edit_patient.dialog.form.pgx_file.validation_empty_error"),
                          ),
                          pdfFile: Yup.mixed().required(
                              t("screen.pgx_admin.add_edit_patient.dialog.form.report_file.validation_empty_error"),
                          ),
                      }),
                component: ({ errors, touched }: ComponentProps<IInternalPatientValues>) => (
                    <>
                        <Field
                            data-id="add_edit_patient_pgx_file"
                            component={CustomDropzone}
                            label={t("screen.pgx_admin.add_edit_patient.dialog.form.pgx_file.label")}
                            name="pgxFile"
                            type="file"
                            fileType="pgx-report"
                            required
                            errorMessage={errors.pgxFile}
                            isTouched={touched.pgxFile}
                            previousFileName={initialValues?.originalPgxName}
                        />
                        <Field
                            data-id="add_edit_patient_pdf_file"
                            component={CustomDropzone}
                            label={t("screen.pgx_admin.add_edit_patient.dialog.form.report_file.label")}
                            name="pdfFile"
                            type="file"
                            fileType="pdf"
                            required
                            errorMessage={errors.pdfFile}
                            isTouched={touched.pdfFile}
                            previousFileName={initialValues?.originalPdfName}
                        />
                    </>
                ),
            },
        ],
    };
};
