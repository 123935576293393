import * as React from "react";
import { TableCell, TableSortLabel } from "@material-ui/core";

type IProps = {
    column?: string;
    label: string | React.ReactNode;
    orderBy?: string;
    orderDirection?: "asc" | "desc";
    onChangeSort: (column: string) => void;
    disable?: boolean;
};

export const SortableTableCell = ({ column, label, orderBy, orderDirection, onChangeSort, disable }: IProps) => (
    <TableCell>
        <TableSortLabel
            active={orderBy === column}
            disabled={!column || !label || disable}
            direction={orderDirection === "asc" || orderBy !== column ? "asc" : "desc"}
            onClick={column && label ? () => onChangeSort(column) : undefined}
        >
            {label}
        </TableSortLabel>
    </TableCell>
);
