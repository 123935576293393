import { TextFieldProps } from "@material-ui/core/TextField";
import { FieldInputProps } from "formik";
import * as React from "react";
import { Colors } from "../util/Colors";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

type IProps = TextFieldProps & {
    errorMessage?: string;
    field: FieldInputProps<string>;
    isTouched?: boolean;
    showValidationErrorText: boolean;
    showPasswordChecker: boolean;
    "data-id"?: string;
};

export const CustomTextEditor = ({
    style,
    errorMessage,
    field,
    isTouched,
    showValidationErrorText = true,
    "aria-label": ariaLabel,
    "data-id": dataId,
}: IProps) => {
    const showError = isTouched && !!errorMessage;
    const modules = {
        toolbar: [
            [{ size: [] }],
            ["bold", "italic", "underline"],
            [{ align: [] }, { list: "ordered" }, { list: "bullet" }],
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    };

    return (
        <div style={style}>
            <ReactQuill modules={modules} value={field.value || ""} onChange={field.onChange(field.name)} />
            {showValidationErrorText && (
                <span
                    data-id={`${dataId}_error`}
                    style={{
                        color: Colors.danger,
                        display: "block",
                        minHeight: 18,
                        width: "100%",
                        marginTop: 4,
                        marginBottom: 10,
                        fontSize: 14,
                    }}
                >
                    {showError && errorMessage}
                </span>
            )}
        </div>
    );
};
