export const Colors = {
    primary: "#2CAAE8",
    secondary: "#27DDE8",
    danger: "#FB5607",
    attention: "#FFBE0B",
    okay: "#28B6A6",
    primaryShade1: "#95D4F3",
    primaryShade2: "#1B86B9",
    replacement: "#052F5F",
    background: "#F9F9F9",
    released: "#29FF00",
};
