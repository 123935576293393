import { Dialog, DialogTitle, Button, DialogActions, DialogContent, Radio } from "@material-ui/core";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { t } from "../../i18n/util";
import { IAnalysisHistoryEntry } from "../../types";
import { InfoContainer } from "./InfoContainer";

const { useState } = React;

const List = styled.ul`
    border: 1px solid #d8d8d8;
    padding: 0 10px;
    list-style: none;
`;

const ListItem = styled.li`
    padding: 10px 15px;
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    flex-flow: column;

    &:hover {
        cursor: pointer;
    }

    &:last-child {
        border-bottom: none;
    }
`;

const ListItemDate = styled.strong`
    display: inline-block;
    width: 130px;
`;

const Remark = styled.span`
    color: #d8d8d8;
    margin-left: 45px;
`;

const HistoryDescription = styled.p`
    margin-bottom: 20px;
`;

type IProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (entry: IAnalysisHistoryEntry) => void;
    entries: IAnalysisHistoryEntry[];
};

export const AnalysisHistoryDialog = ({ isOpen, onConfirm, onClose, entries }: IProps) => {
    const [selectedEntry, setSelectedEntry] = useState<IAnalysisHistoryEntry>();

    const isChecked = (entry: IAnalysisHistoryEntry): boolean => {
        return entry.createdAt === selectedEntry?.createdAt;
    };

    const ListItems = entries.map(entry => (
        <ListItem key={entry.createdAt} onClick={() => setSelectedEntry(entry)}>
            <span>
                <Radio checked={isChecked(entry)} name="entry-selection" />
                <ListItemDate>{moment(entry.createdAt).format("DD.MM.YYYY")}</ListItemDate>
                <span>
                    {entry.doctor.firstname} {entry.doctor.lastname}
                </span>
            </span>
            <Remark>{entry.remarks}</Remark>
        </ListItem>
    ));

    const handleConfirm = () => {
        if (selectedEntry) {
            onConfirm(selectedEntry);
            setSelectedEntry(undefined);
        }
    };

    const handleClose = () => {
        setSelectedEntry(undefined);
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle id="alert-dialog-title">{t("history.title")}</DialogTitle>
            <DialogContent>
                <HistoryDescription>{t("history.description")}</HistoryDescription>

                <InfoContainer headline={t("history.info.headline")} text={t("history.info.text")} />
                <List>{ListItems}</List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t("confirm_resend_dialog.cancel.label")}
                </Button>
                <Button disabled={!selectedEntry} onClick={handleConfirm} variant="contained" color="primary" autoFocus>
                    {t("history.open_history_button.text")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
