import * as React from "react";
import { CenteredContainer } from "../containers/CenteredContainer";
import { SetPasswordForm, ISetPasswordValues } from "../forms/SetPasswordForm";
import { t } from "../../i18n/util";
import { useParams } from "react-router";
import { generalStore } from "../../stores/GeneralStore";
import { API } from "../../network/API";
import { observer } from "mobx-react";
import { history } from "../routers/history";
import { Routes } from "../routers/Routes";

export const SetPasswordSite = observer(() => {
    const [success, setSuccess] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [validToken, setValidToken] = React.useState(true);

    const { token } = useParams<{ token?: string }>();

    React.useEffect((): void => {
        generalStore.isLoading = true;

        const checkValidity = async (): Promise<void> => {
            try {
                if (token) {
                    const requestResult = await API.checkTokenValidity({ token });

                    setValidToken(requestResult.valid);
                }
            } catch (error) {
                setValidToken(false);
            } finally {
                generalStore.isLoading = false;
            }
        };

        checkValidity();
    }, [token]);

    const handleSubmit = async (model: ISetPasswordValues) => {
        generalStore.isLoading = true;

        try {
            if (token) {
                const { success } = await API.setPassword({ token, password: model.password });

                if (success) {
                    setSuccess(true);
                    setMessage(t("screen.set_password.form.success"));

                    setTimeout(() => {
                        history.push(Routes.ROOT);
                    }, 5000);
                }
            }
        } catch (error) {
            setSuccess(false);
            setMessage(error.statusText);
        }

        generalStore.isLoading = false;
    };

    return (
        <CenteredContainer>
            {validToken ? (
                <>
                    <h4>{t("screen.set_password.title")}</h4>
                    <div style={{ marginTop: 14, marginBottom: 35 }}>{t("screen.set_password.description")}</div>
                    <SetPasswordForm success={success} message={message} onSubmit={handleSubmit} />
                </>
            ) : (
                <>
                    <h4>{t("screen.set_password.invalid_token.title")}</h4>
                    <div style={{ marginTop: 14, marginBottom: 14 }}>
                        {t("screen.set_password.invalid_token.description")}
                    </div>
                    <a href={Routes.ROOT}>{process.env.PUBLIC_URL}</a>
                </>
            )}
        </CenteredContainer>
    );
});
