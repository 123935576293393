import * as React from "react";
import logo from "../../assets/images/logo_pharmgenetix_einzeilig.svg";
import empty from "../../assets/images/im_empty.svg";
import folder from "../../assets/images/im_folder.svg";
import error from "../../assets/images/im_errorimage.png";
import loading from "../../assets/images/im_loading.svg";
import triangle from "../../assets/images/im_triangle.svg";

export const ImageLogo = (props: { className?: string }) => (
    <img data-id="image_logo_single_line" alt="Logo" src={logo} className={props.className} />
);

export const ImageEmpty = (props: { className?: string }) => (
    <img data-id="image_empty" alt="Empty" src={empty} className={props.className} />
);

export const ImageFolder = (props: { className?: string }) => (
    <img data-id="image_folder" alt="Folder" src={folder} className={props.className} />
);

export const ImageError = (props: { className?: string }) => (
    <img data-id="image_error" alt="Error" src={error} className={props.className} />
);

export const ImageLoading = (props: { className?: string }) => (
    <img data-id="image_loading" alt="Loading" src={loading} className={props.className} />
);

export const ImageTriangle = (props: { className?: string }) => (
    <img data-id="image_triangle" alt="Triangle" src={triangle} className={props.className} />
);
