import { action, computed, observable } from "mobx";
import { create, persist } from "mobx-persist";
import * as config from "../config";
import { API, STATUS_CODE_UNAUTHORIZED } from "../network/API";
import { doctorStore } from "./DoctorStore";
import { pgxAdminStore } from "./PgxAdminStore";
import { patientStore } from "./PatientStore";
import { IGetAuthProfileResponse } from "../types";
import { setLocale } from "../i18n/util";
import { newsStore } from "./NewsStore";

export type ICredentials = {
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    tokenType: string;
};

export type ILoginResponse = ICredentials & {
    uid: string;
    scope: string[];
};

export type IProfile = {
    user: any; // TODO type
    scope: string[];
};

export type IAuthError = "PasswordWrong" | "Unknown";

class Auth {
    @persist("object") @observable credentials: ICredentials | null = null;
    @persist("object") @observable userProfile: IProfile | null = null;
    @persist("object") @observable authProfile: IGetAuthProfileResponse["profile"] | null = null;
    @persist @observable username = "";
    @observable error: IAuthError | null = null;
    @observable isAuthenticated = false;
    @observable isLoading = false;
    @observable isRehydrated = false;
    @observable globalLegalUpdatedAt: string | null = null;

    @action loginWithPassword = async (username: string, password: string) => {
        if (this.isLoading) {
            // bailout, noop
            return;
        }

        this.isLoading = true;

        try {
            const { accessToken, refreshToken, expiresIn, tokenType, scope, uid } = await API.loginWithPassword({
                username: username,
                password: password,
            });

            this.error = null;
            this.username = username;
            this.isLoading = false;
            this.credentials = { accessToken, refreshToken, expiresIn, tokenType };

            await this.getUserProfile(scope, uid, username);

            const { profile } = await API.getAuthProfile();
            this.authProfile = profile;

            setLocale(this.authProfile.language);

            // This has to be last! Because setting isAuthenticated to true triggers the <PublicRoute> component
            // to start redirecting in which case the credentials must be valid.
            this.isAuthenticated = true;
        } catch (error) {
            this.isLoading = false;

            if (error.statusCode === STATUS_CODE_UNAUTHORIZED) {
                this.wipe("PasswordWrong");
            } else {
                this.wipe("Unknown");
            }
        }
    };

    @action logout() {
        pgxAdminStore.wipe();
        doctorStore.wipe();
        patientStore.wipe();
        newsStore.wipe();
        doctorStore.showAppWarningDialog = true;
        patientStore.showAppWarningDialog = true;
        this.wipe(null);
    }

    @action tokenExchange = async () => {
        this.isLoading = true;

        try {
            if (this.credentials === null) {
                throw new Error(`No valid credentials are available`);
            }

            const res = await fetch(`${config.API_BASE_URL}/api/v1/auth/refresh`, {
                method: "POST",
                body: JSON.stringify({
                    refreshToken: this.credentials.refreshToken,
                }),
            });

            if (!res.ok) {
                throw Error(`${res.status}: ${res.statusText}`);
            }

            const { accessToken, refreshToken, expiresIn, tokenType } = await res.json();

            this.credentials = {
                accessToken,
                refreshToken,
                expiresIn,
                tokenType,
            };
            this.error = null;
            this.isAuthenticated = true;
            this.isLoading = false;
        } catch (e) {
            this.wipe("Unknown");
        }
    };

    @action tokenCheck = () => {
        if (this.credentials) {
            this.refreshUserProfile();
            this.isAuthenticated = true;
        }
    };

    @action private wipe(error: IAuthError | null) {
        this.credentials = null;
        this.error = error;
        this.isAuthenticated = false;
        this.isLoading = false;
        this.userProfile = null;
    }

    @action getUserProfile = async (scope: string[], uid: string, username: string) => {
        try {
            if (scope.includes("patient") || scope.includes("patientPLUS")) {
                const user = await API.getPatient(uid);
                this.userProfile = { scope, user };
            } else if (scope.includes("doctor")) {
                const user = await API.getDoctor(uid);
                this.userProfile = { scope, user };
            } else {
                this.userProfile = { scope, user: { username } };
            }
        } catch (error) {
            console.error(error);
        }
    };

    @action refreshUserProfile = () => {
        if (this.userProfile) {
            const {
                scope,
                user: { uid, username },
            } = this.userProfile;

            return this.getUserProfile(scope, uid, username);
        }
    };

    @computed get userDisplayName() {
        const scope = this.userProfile?.scope ?? [];
        const { firstname, lastname, username } = this.userProfile?.user;

        if (scope.includes("patient") || scope.includes("patientPLUS") || scope.includes("doctor")) {
            return `${firstname} ${lastname}`;
        }

        return username;
    }
}

let authStore: Auth;
if (process.env.NODE_ENV === "test") {
    class MockAuth {
        @observable credentials: any = null;
        @observable isAuthenticated = false;
        @observable error: any = null;
        @observable isRehydrated = true;

        @action loginWithPassword = () => undefined;
        @action dismissError = () => undefined;
        @action logout = () => undefined;
    }

    authStore = new MockAuth() as any; // no localstorage support in node env
} else {
    // persist this mobx state through localforage
    const hydrate = create({
        storage: require("localforage"),
    });
    authStore = new Auth();

    hydrate("auth", authStore)
        .then(() => {
            authStore.tokenCheck();
            authStore.isRehydrated = true;
        })
        .catch(error => {
            console.error(error);
        });
}

// development, make auth available on window object...
(window as any).auth = authStore;

// singleton, exposes an instance by default
export { authStore };
