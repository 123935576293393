import { flatMap, mapValues, omitBy, zipObject } from "lodash";
import { action, observable } from "mobx";
import { create, persist } from "mobx-persist";
import { t } from "../i18n/util";
import { API, isUnauthorizedError } from "../network/API";
import { IAtcCode, IParsedGetSubstancesResponse } from "../types";
import { generalStore } from "./GeneralStore";

type IAtcCodeToIdMap = { [atcCode in IAtcCode]: string };

// Filter whole tree to only show available atcCodes and their internal substanceId
const filterTree = (tree: any, availableAtcCodes: IAtcCode[], atcCodeToId: IAtcCodeToIdMap): any => {
    // When there are children, just return them
    if (tree.children) {
        return {
            ...tree,
            children: filterTree(tree.children, availableAtcCodes, atcCodeToId),
        };
    }

    // Here we are definitely in a groups object
    const groups = mapValues(tree, value => {
        // When there are children, it is not a substance and therefor not a leaf node
        if (value.children) {
            if (availableAtcCodes.includes(value.atcCode)) {
                // when a group has an internal substanceId list it too
                return filterTree(
                    { ...value, substanceId: atcCodeToId[value.atcCode] },
                    availableAtcCodes,
                    atcCodeToId,
                );
            }

            return filterTree(value, availableAtcCodes, atcCodeToId);
        }

        // When the atcCode can be found in all available substances leave as is
        if (availableAtcCodes.includes(value.atcCode)) {
            return { ...value, substanceId: atcCodeToId[value.atcCode] }; // save the internal substanceId of the opimizer, which will be sent back instead of the atcCode
        }

        // undefined otherwise
        return undefined;
    });

    // then get rid of undefined values
    return omitBy(groups, substance => !substance);
};

class OptimizerStore {
    @persist("object") @observable substances: IParsedGetSubstancesResponse | null = null;
    @persist("object") @observable atcCodeToId: IAtcCodeToIdMap | null = null;
    @observable optimizerThreshold: number | null = null;
    @observable isLoading = false;
    @observable isRehydrated = false;
    @observable isInitialized = false;

    @action loadSubstances = async (version?: string) => {
        let substancesVersion;

        if (!version) {
            try {
                const versionsResponse = await API.getVersions();
                substancesVersion = versionsResponse.version.substances;
                this.optimizerThreshold = versionsResponse.optimizerThreshold;
            } catch (error) {
                if (!isUnauthorizedError(error)) {
                    generalStore.errorMessage = t("error.getStatus");
                }
                console.error(error);
            }
        }

        try {
            if (
                !this.isLoading &&
                (this.substances === null ||
                    (version && version !== this.substances.version) ||
                    this.substances.version !== substancesVersion)
            ) {
                this.isLoading = true;

                const response = await API.getSubstances();
                const atcTree = JSON.parse(response.atcTree);
                const atcCodes = response.atcCodes;
                const allAtcCodes = flatMap(Object.keys(atcCodes), atcCode => atcCodes[atcCode] || []);

                const atcCodeToId = Object.keys(atcCodes).reduce((accumulator, substanceId) => {
                    const atcCodesArray = atcCodes[substanceId];
                    return {
                        ...accumulator,
                        ...(atcCodesArray
                            ? zipObject(atcCodesArray, new Array(atcCodesArray.length).fill(substanceId))
                            : {}),
                    };
                }, {});

                const filteredAtcTree = filterTree(atcTree, allAtcCodes, atcCodeToId);

                this.substances = { ...response, atcTree: filteredAtcTree };
                this.atcCodeToId = atcCodeToId;
            }
        } catch (error) {
            if (!isUnauthorizedError(error)) {
                generalStore.errorMessage = t("error.loadSubstances");
            }
            console.error(error);
        }

        this.isLoading = false;
        this.isInitialized = true;
    };
}

const hydrate = create({
    storage: require("localforage"),
});

const optimizerStore = new OptimizerStore();

hydrate("optimizer", optimizerStore)
    .then(() => {
        optimizerStore.isRehydrated = true;
    })
    .catch(error => {
        console.error(error);
    });

export { optimizerStore };
