import { Dialog, DialogTitle, Button, DialogActions } from "@material-ui/core";
import React from "react";
import { t } from "../../../../i18n/util";

type IProps = {
    isOpen: boolean;
    action: () => void;
    onClose: () => void;
};

export const ConfirmDeleteNewsDialog = ({ isOpen, action, onClose }: IProps) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{t("confirm_delete_dialog.headline.text")}</DialogTitle>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t("confirm_resend_dialog.cancel.label")}
                </Button>
                <Button variant="contained" onClick={action} color="primary" autoFocus>
                    {t("confirm_delete_dialog.delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
