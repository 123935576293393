import * as React from "react";

export const useTableSort = (initialSort: string, initialSortDirection: "asc" | "desc" = "asc") => {
    const [orderBy, setOrderBy] = React.useState(initialSort);
    const [orderDirection, setOrderDirection] = React.useState<"asc" | "desc">(initialSortDirection);
    const onChangeSort = (column: string) => {
        if (column === orderBy) {
            setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
        } else {
            setOrderBy(column);
            setOrderDirection("asc");
        }
    };

    return { orderBy, orderDirection, onChangeSort };
};
