import { InputAdornment, TextField, TextFieldProps, withStyles } from "@material-ui/core";
import { Autocomplete, AutocompleteProps, FilterOptionsState, UseAutocompleteSingleProps } from "@material-ui/lab";
import { flatMap, uniq } from "lodash";
import { observer } from "mobx-react";
import * as React from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { t } from "../../i18n/util";
import { optimizerStore } from "../../stores/OptimizerStore";
import { ISubstanceId } from "../../types";
import { Colors } from "../util/Colors";
import { Icon } from "../util/Icon";
import { getTranslation } from "../util/Optimizer";

type IOption = {
    value: string;
    label: string;
    isTradeName?: boolean;
    indent: number;
};

const ITEM_SIZE = 58;
const LISTBOX_PADDING = 0;

const StyledAutocomplete = withStyles({
    option: {
        height: ITEM_SIZE,
        padding: "20px 16px",
        borderBottom: "2px solid #e1e1e1",
    },
    listbox: {
        padding: LISTBOX_PADDING,
    },
    inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
            padding: "14px 16px 13px 12px !important",
        },
        '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
            padding: 0,
        },
        '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            padding: 0,
        },
    },
})((props: AutocompleteProps<IOption> & UseAutocompleteSingleProps<IOption>) => <Autocomplete {...props} />);

const renderRow = (props: ListChildComponentProps) => {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: (style.top as number) + LISTBOX_PADDING,
        },
    });
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
    const { children, ...restProps } = props;
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * ITEM_SIZE;
        }
        return itemData.length * ITEM_SIZE;
    };

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={restProps}>
                <FixedSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={ITEM_SIZE}
                    overscanCount={10}
                    itemCount={itemCount}
                >
                    {renderRow}
                </FixedSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

type IProps = {
    substances: ISubstanceId[];
    onSelect: (substanceId: ISubstanceId, tradeName?: string) => void;
    placeholder?: string;
    resetOnSelect?: boolean;
    "data-id"?: string;
    style: React.CSSProperties;
};

export const SubstanceAutocompleteSearchbar = observer(
    ({ substances, onSelect, resetOnSelect, placeholder, "data-id": dataId, style }: IProps) => {
        const options = flatMap(substances, substanceId => {
            const tradeNames = optimizerStore.substances?.tradeNames[substanceId];
            const tradeNameOptions = (tradeNames || []).map(tradeName => ({
                value: substanceId,
                label: tradeName,
                isTradeName: true,
                indent: 1,
            }));

            // Create a hierarchy of Substance -> trade name via indent
            // The value is the substance id for both and all options should be removed after one option of one hierarchy branch is selected
            return [
                {
                    value: substanceId,
                    label: getTranslation(substanceId),
                    indent: 0,
                },
                ...tradeNameOptions,
            ];
        });
        const [value, setValue] = React.useState("");
        const [selectedOption, setSelectedOption] = React.useState<IOption | null>(null);

        const handleReset = () => {
            setValue("");
        };

        const handleSelect = (_: any, option: IOption | null) => {
            if (option) {
                onSelect(option.value, option.isTradeName ? option.label : undefined);
                if (resetOnSelect) {
                    handleReset();
                    setSelectedOption(null);
                } else {
                    setSelectedOption(option);
                }
            }
        };

        return (
            <StyledAutocomplete
                onChange={handleSelect}
                options={options}
                getOptionLabel={(option: IOption) => option.label}
                value={selectedOption}
                onInputChange={(_, value) => setValue(value)}
                inputValue={value}
                clearOnEscape
                blurOnSelect
                autoHighlight
                disableClearable
                openOnFocus
                noOptionsText={t("select.no_options")}
                style={style}
                ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
                filterOptions={(options: IOption[], state: FilterOptionsState<IOption>) => {
                    const distinctValues = uniq(
                        options
                            .filter(option => option.label.toLowerCase().includes(state.inputValue.toLowerCase()))
                            .map(option => option.value),
                    );

                    const filteredOptions = options.filter(
                        option =>
                            (distinctValues.includes(option.value) && option.indent === 0) ||
                            option.label.toLowerCase().includes(state.inputValue.toLowerCase()),
                    );

                    return filteredOptions;
                }}
                renderOption={(option: IOption) => (
                    <span style={{ paddingLeft: option.indent * 24 }}>{option.label}</span>
                )}
                renderInput={(params: TextFieldProps) => (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        variant="outlined"
                        fullWidth
                        inputProps={{
                            ...params.inputProps,
                            "data-id": dataId,
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    {value ? (
                                        <Icon name="x" hoverColor={Colors.secondary} onClick={handleReset} />
                                    ) : (
                                        <Icon name="search" />
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />
        );
    },
);
