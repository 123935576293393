import { withStyles } from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import * as React from "react";

const StyledPagination = withStyles({
    root: {
        marginTop: 28,
    },
    ul: {
        justifyContent: "flex-end",
    },
})(Pagination);

const StyledPaginationItem = withStyles({
    selected: {
        color: "#ffffff !important",
    },
    sizeSmall: {
        fontSize: 14,
        maxWidth: 20,
        minWidth: 20,
        height: 20,
    },
})(PaginationItem);

type IProps = {
    count: number;
    rowsPerPage: number;
    page: number;
    onChangePage: (page: number) => void;
    "data-id"?: string;
};

export const CustomPagination = ({ count, rowsPerPage, page, onChangePage, "data-id": dataId }: IProps) => {
    const pageCount = Math.ceil(count / rowsPerPage);
    const handleChangePage = (_: any, page: number) => {
        onChangePage(page);
    };

    return (
        <StyledPagination
            color="primary"
            count={pageCount}
            page={page}
            onChange={handleChangePage}
            size="small"
            data-id={dataId}
            renderItem={(item: any) => <StyledPaginationItem {...item} data-id={`page_${item.page}`} />}
        />
    );
};
