import { KeyboardDatePicker, KeyboardDatePickerProps } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { FieldInputProps, FormikProps, FormikValues } from "formik";
import * as React from "react";
import { t } from "../../i18n/util";
import { Colors } from "../util/Colors";
import moment, { Moment } from "moment";

type IProps<Values extends FormikValues> = KeyboardDatePickerProps & {
    errorMessage?: string;
    field: FieldInputProps<Moment>;
    form: FormikProps<Values>;
    isTouched?: boolean;
    showValidationErrorText: boolean;
    "data-id"?: string;
};

export const CustomDatePicker = <Values extends FormikValues>({
    style,
    label,
    required,
    errorMessage,
    field,
    form,
    isTouched,
    showValidationErrorText = true,
    "data-id": dataId,
}: IProps<Values>) => {
    const showError = isTouched && !!errorMessage;
    const currentError = form.errors[field.name];

    const handleChange = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
        form.setFieldValue(field.name, date, true);
    };

    return (
        <div style={style}>
            <KeyboardDatePicker
                clearable
                margin="normal"
                label={required ? `${label} *` : label}
                format="DD.MM.YYYY"
                name={field.name}
                onChange={handleChange}
                onBlur={field.onBlur}
                value={field.value || null}
                fullWidth
                onError={error => {
                    if (field.value && error !== currentError) {
                        form.setFieldError(field.name, error as string);
                    }
                }}
                error={showError}
                inputVariant="outlined"
                invalidDateMessage={t("date.validation_error")}
                minDateMessage={t("date.validation_error")}
                maxDate={moment.now()}
                maxDateMessage={t("date.validation_error")}
                helperText={undefined}
                KeyboardButtonProps={{
                    "aria-label": "change date",
                }}
                clearLabel={t("date.clearLabel")}
                cancelLabel={t("date.cancelLabel")}
                style={{ margin: 0 }}
                inputProps={{ "data-id": dataId }}
            />
            {showValidationErrorText && (
                <span
                    style={{
                        color: Colors.danger,
                        display: "block",
                        minHeight: 18,
                        width: "100%",
                        marginTop: 4,
                        marginBottom: 10,
                        fontSize: 14,
                    }}
                >
                    {showError && errorMessage}
                </span>
            )}
        </div>
    );
};
