import { Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from "@material-ui/core";
import * as React from "react";
import { t } from "../../i18n/util";
import { INewsValues } from "../../types";
import { UnreadNews } from "../sites/doctor/NewsSite/UnreadNews";
import { Colors } from "../util/Colors";
import { Icon } from "../util/Icon";

type IProps = {
    open: boolean;
    onSubmit: () => void;
    onClose?: () => void;
    unreadNews: INewsValues[];
};

const StyledDialog = withStyles({
    paper: {
        padding: 40,
        "& > *": {
            padding: 0,
        },
    },
})(Dialog);

export const UnreadNewsDialog = ({ open, onSubmit, onClose, unreadNews }: IProps) => (
    <StyledDialog open={open} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
        <DialogTitle disableTypography id="form-dialog-title" style={{ marginBottom: 24, position: "relative" }}>
            <h3>{t("news.unreadNewsDialog.title")}</h3>
            <Icon
                name="x"
                data-id="dialog_close_button"
                onClick={onClose}
                hoverColor={Colors.secondary}
                style={{ position: "absolute", top: 0, right: 0 }}
            />
        </DialogTitle>
        <DialogContent style={{ marginBottom: 32 }}>
            {unreadNews.map(news => (
                <UnreadNews key={news.uid} news={news} hideDot />
            ))}
        </DialogContent>
        <DialogActions>
            <Button onClick={onSubmit} variant="contained" color="primary">
                {t("news.unreadNewsDialog.button.text")}
            </Button>
        </DialogActions>
    </StyledDialog>
);
