import { Button, withStyles, Tooltip } from "@material-ui/core";
import * as React from "react";
import styled, { css } from "styled-components";
import { t } from "../../../../../i18n/util";
import { IOptimizerMedication, IEnzyme } from "../../../../../types";
import { Badge } from "../../../../util/Badge";
import { Icon } from "../../../../util/Icon";
import { getTranslation } from "../../../../util/Optimizer";

const StyledButton = withStyles({
    root: {
        letterSpacing: 1,
        padding: 0,
    },
})(Button);

type ICombinationItemContainerProps = {
    canHover?: boolean;
};

const CombinationItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
    flex-shrink: 0;
    padding: 0px 24px;
    word-break: break-all;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    ${({ canHover }: ICombinationItemContainerProps) =>
        canHover &&
        css`
            &:hover {
                background-color: #ffffff;
                border-top: 2px solid #e1e1e1;
                border-bottom: 2px solid #e1e1e1;
            }
        `}
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    height: 58px;
`;

type IInfoRowProps = {
    visible?: boolean;
};

const InfoRow = styled(Row)`
    align-items: flex-start;
    height: ${({ visible }: IInfoRowProps) => (visible ? "43px" : "0px")};
    opacity: ${({ visible }: IInfoRowProps) => (visible ? 1 : 0)};
    visibility: ${({ visible }: IInfoRowProps) => (visible ? "visible" : "hidden")};
    font-size: 14px;
    color: #878787;
    /* transition: height 0.5s ease-in-out, opacity 0.25s ease-in-out 0.25s; */
`;

const VerticalCenter = styled.div`
    display: flex;
    align-items: center;
    & > * + * {
        margin-left: 8px;
    }
`;

const Heading = styled(Row)`
    font-size: 16px;
    color: #a1a1a1;
    font-weight: bold;
    letter-spacing: 0.5px;
`;

const LabeledBadge = React.forwardRef<
    HTMLDivElement,
    { isHovered?: boolean; enzyme: IEnzyme; [otherProp: string]: any }
>(({ isHovered, enzyme, ...props }, ref: any) => (
    <VerticalCenter ref={ref} {...props}>
        {enzyme.missing ? (
            <Badge color={isHovered ? "gray" : "white"} value="unknown" isEnzyme />
        ) : (
            <Badge color={isHovered ? "gray" : "white"} value={enzyme.fc} isEnzyme />
        )}
        <span>{enzyme.name}</span>
    </VerticalCenter>
));

type IProps = {
    combination: IOptimizerMedication;
    title: string;
    showDetailInfo?: boolean;
    onClickSelect?: (combination: IOptimizerMedication) => void;
    maxEnzymeRows: number[];
    isHovered?: boolean;
};

export const CombinationItem = React.memo(
    ({ combination, title, showDetailInfo, onClickSelect, maxEnzymeRows, isHovered }: IProps) => {
        const handleClickSelect = () => {
            if (onClickSelect) {
                onClickSelect(combination);
            }
        };

        return (
            <CombinationItemContainer canHover={!!onClickSelect}>
                <Heading>{title}</Heading>
                {combination.medication.map((substance, index) => {
                    const interactionEnzymes = substance?.interaction?.enzymes || [];

                    // add empty cells in case there is a combination with different amount of enzymes
                    const emptyEnzymeRows =
                        interactionEnzymes.length !== maxEnzymeRows[index]
                            ? new Array(maxEnzymeRows[index] - (interactionEnzymes.length ?? 0))
                                  .fill(null)
                                  .map((_, index) => (
                                      <InfoRow visible={showDetailInfo} key={`empty_${index}`}>
                                          &nbsp;
                                      </InfoRow>
                                  ))
                            : null;

                    return substance ? (
                        <div key={substance.id}>
                            <Row>
                                <VerticalCenter>
                                    <Badge value={substance.interaction?.fc} />
                                    <span>{getTranslation(substance.id)}</span>
                                </VerticalCenter>
                            </Row>
                            {interactionEnzymes.map((enzyme, enzymeIndex) => (
                                <InfoRow visible={showDetailInfo} key={`${enzyme.name}_${enzymeIndex}`}>
                                    <Tooltip
                                        arrow
                                        placement="top"
                                        title={
                                            <div>
                                                {t("common.enzyme.interaction_with")}:
                                                {enzyme.substances.map(substanceId => (
                                                    <div key={substanceId}>{getTranslation(substanceId)}</div>
                                                ))}
                                            </div>
                                        }
                                    >
                                        <LabeledBadge enzyme={enzyme} isHovered={isHovered} />
                                    </Tooltip>
                                </InfoRow>
                            ))}
                            {emptyEnzymeRows}
                        </div>
                    ) : (
                        <div key={`empty_substance_${index}`}>
                            <Row />
                            {emptyEnzymeRows}
                        </div>
                    );
                })}
                <Row>
                    {onClickSelect && (
                        <StyledButton size="small" disableRipple onClick={handleClickSelect}>
                            {t("screen.doctor.auto_optimize.section.combination.select")}
                            <Icon name="chevronRight" color="currentColor" />
                        </StyledButton>
                    )}
                </Row>
            </CombinationItemContainer>
        );
    },
);
