import styled from "styled-components";

export const TableActionsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    & > * + * {
        margin-left: 24px;
    }
`;
