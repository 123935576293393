import { Dialog, DialogTitle, Button, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import React from "react";
import { t } from "../../i18n/util";
import { generalStore } from "../../stores/GeneralStore";

type IProps = {
    isOpen: boolean;
    headline?: string;
    text?: string | React.ReactElement;
    isDisabled?: boolean;
    okButtonText: string;
    action: () => void;
    onClose: () => void;
};

export const ConfirmDialog = ({ isOpen, action, onClose, headline, text, okButtonText, isDisabled }: IProps) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {headline && <DialogTitle id="alert-dialog-title">{headline}</DialogTitle>}
            {text && <DialogContent>{text}</DialogContent>}
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t("confirm_resend_dialog.cancel.label")}
                </Button>
                <Button disabled={isDisabled} variant="contained" onClick={action} color="primary" autoFocus>
                    {okButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
