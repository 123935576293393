import { Button, withStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import { t } from "../../../../../i18n/util";
import { doctorStore } from "../../../../../stores/DoctorStore";
import { generalStore } from "../../../../../stores/GeneralStore";
import { patientStore } from "../../../../../stores/PatientStore";
import { ISubstance } from "../../../../../types";
import { Legend } from "../../../../ui/Legend";
import { EmptyHint } from "../EmptyHint";
import { GroupsTable } from "./components/GroupsTable";
import { authStore } from "../../../../../stores/AuthStore";
import { LoadingOverlay } from "../../../../ui/LoadingOverlay";

const StyledButton = withStyles({
    root: {
        height: 66,
        borderRadius: 0,
        flexBasis: 0,
        flexGrow: 1,
    },
})(Button);

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Content = styled.div`
    flex-grow: 1;
    margin-right: 40px;
`;

const Heading = styled.h3`
    margin-bottom: 24px;
`;

const TextContainer = styled.div`
    margin-bottom: 32px;
`;

const PrintOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    z-index: 10000;
    padding-bottom: 66px;

    @media print {
        padding-bottom: 0px;
    }
`;

const PrintInformationSection = styled.div`
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    height: 66px;
    background-color: #ffffff;

    button + button {
        margin-left: 1px;
    }

    @media print {
        display: none;
    }
`;

const PrintPatientDetailContainer = styled.div`
    font-size: 16px;
    padding: 16px;
`;

const PrintPatientNameLabel = styled.div`
    color: #a1a1a1;
    font-weight: bold;
    margin-bottom: 8px;
`;

export type ISingleMedGroup = {
    name: string;
    substances: ISubstance[];
};

export const MedicationGroups = observer(() => {
    const [openGroups, setOpenGroups] = React.useState({});
    const [printGroup, setPrintGroup] = React.useState<ISingleMedGroup | null>(null);

    React.useEffect(() => {
        window.onafterprint = () => {
            handleClosePrintOverlay();
        };

        const getMedicationGroups = async () => {
            if (authStore.userProfile?.scope.includes("doctor") && doctorStore.selectedPatient) {
                await patientStore.loadGroups(doctorStore.selectedPatient);
            }
        };

        getMedicationGroups();
    }, []);

    const handleOpenGroup = (group: ISingleMedGroup) => {
        setOpenGroups(prevState => ({ ...prevState, [group.name]: !prevState[group.name] }));
    };

    const handlePrintGroup = (group: ISingleMedGroup) => {
        setPrintGroup(group);
    };

    const handleClosePrintOverlay = () => {
        setPrintGroup(null);
    };

    const handlePrint = () => {
        window.print();
    };

    if (patientStore.isLoading) {
        return <LoadingOverlay />;
    }

    let patientName = "";

    if (authStore.userProfile?.scope.includes("doctor") && doctorStore.selectedPatient) {
        patientName = `${doctorStore.selectedPatient.firstname} ${doctorStore.selectedPatient.lastname}`;
    } else {
        patientName = authStore.userDisplayName;
    }

    return printGroup ? (
        <PrintOverlay>
            <PrintInformationSection>
                <StyledButton variant="contained" color="primary" onClick={handlePrint}>
                    {t("common.print")}
                </StyledButton>
                <StyledButton variant="contained" color="primary" onClick={handleClosePrintOverlay}>
                    {t("common.print.close")}
                </StyledButton>
            </PrintInformationSection>
            <PrintPatientDetailContainer>
                <PrintPatientNameLabel>{t("screen.patient.medication_groups.print.name.label")}</PrintPatientNameLabel>
                <div>{patientName}</div>
            </PrintPatientDetailContainer>
            <GroupsTable data-id="medication_group_table" group={printGroup} open showButtons={false} />
        </PrintOverlay>
    ) : (
        <Container>
            <Content>
                <Heading>{t("screen.patient.medication_groups.heading")}</Heading>
                <TextContainer>{t("screen.patient.medication_groups.text")}</TextContainer>
                {patientStore.groups.length === 0 ? (
                    <EmptyHint />
                ) : (
                    patientStore.groups.map((group, index) => (
                        <GroupsTable
                            key={group.name}
                            group={group}
                            data-id={`medication_group_table_${index}`}
                            open={!!openGroups[group.name]}
                            onToggleOpen={handleOpenGroup}
                            onClickPrint={handlePrintGroup}
                        />
                    ))
                )}
            </Content>
            <Legend />
        </Container>
    );
});
