import * as React from "react";
import { history } from "./history";

type IProps = {
    children: React.ReactNode;
};

export const RoutingManager = ({ children }: IProps) => {
    const [lastLocation, setLastLocation] = React.useState(history.location);

    React.useEffect(() => {
        const unlistenHistory = history.listen((location: any, action: any) => {
            if (location !== lastLocation) {
                console.log(
                    `%cSwitching url to "${location.pathname}${location.search}${location.hash}" by action ${action}.`,
                    "background: #eee; color: #666;",
                );
                setLastLocation(location);
            }
        });

        return () => {
            unlistenHistory();
        };
    }, [lastLocation]);

    return <>{children}</>;
};
