import React from "react";
import styled from "styled-components";

type IProps = {
    status?: string;
};

enum ETransactionalEvents {
    sent = "request",
    clicked = "click",
    deferred = "deferred",
    delivered = "delivered",
    softBounced = "soft_bounce",
    complaint = "complaint",
    firstOpening = "unique_opened",
    hardBounced = "hard_bounce",
    opened = "opened",
    invalidEmail = "invalid_email",
    blocked = "blocked",
    error = "error",
    unsubscribe = "unsubscribe",
}

enum EStatusColor {
    red = "#FB5607",
    yellow = "#FFBE0B",
    green = "#06BC06",
    blue = "#2CAAE8",
}

const Circle = styled.div`
    display: block;
    width: 11px;
    height: 11px;
    border-radius: 5px;
    position: absolute;
    bottom: -1px;
    right: -4px;
    border: 2px solid white;
    background-color: ${props => props.color || EStatusColor.red};
`;

const getStatusColor = (status?: string) => {
    if (!status) {
        return EStatusColor.red;
    }

    switch (status) {
        case ETransactionalEvents.clicked:
        case ETransactionalEvents.firstOpening:
        case ETransactionalEvents.opened:
            return EStatusColor.green;
        case ETransactionalEvents.sent:
        case ETransactionalEvents.deferred:
        case ETransactionalEvents.delivered:
        case ETransactionalEvents.complaint:
            return EStatusColor.yellow;
        case ETransactionalEvents.error:
        case ETransactionalEvents.hardBounced:
        case ETransactionalEvents.softBounced:
        case ETransactionalEvents.invalidEmail:
        case ETransactionalEvents.blocked:
        case ETransactionalEvents.unsubscribe:
            return EStatusColor.red;
        default:
            return EStatusColor.blue;
    }
};

export const EmailStatus: React.FunctionComponent<IProps> = ({ status }) => {
    const color = getStatusColor(status);
    return color ? <Circle color={color} /> : null;
};
