import { t } from "../../i18n/util";
import { generalStore } from "../../stores/GeneralStore";

export const copyTextToClipboard = (text: string) => {
    const textArea = document.createElement("textarea");

    textArea.value = text;
    textArea.style.position = "fixed"; // prevents jumping to bottom on mobile if there is a scrollbar
    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    try {
        document.execCommand("copy");
    } catch (error) {
        generalStore.errorMessage = t("error.copy");
    }

    document.body.removeChild(textArea);
};
