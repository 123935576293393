import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import { observer } from "mobx-react";
import React from "react";
import { generalStore } from "../../stores/GeneralStore";

const SlideTransition = (props: any) => {
    return <Slide {...props} direction="up" />;
};

export const SuccessToast = observer(() => {
    const handleClose = () => (generalStore.successMessage = undefined);

    return (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={!!generalStore.successMessage}
            key={generalStore.successMessage}
            onClose={handleClose}
            autoHideDuration={2000}
            message={generalStore.successMessage}
            TransitionComponent={SlideTransition}
            ClickAwayListenerProps={{ mouseEvent: "onMouseDown" }}
            ContentProps={{
                style: {
                    backgroundColor: "#727272",
                    color: "#FFFFFF",
                    fontSize: 14,
                },
            }}
        />
    );
});
