import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import { t } from "../../../../../i18n/util";
import { doctorStore } from "../../../../../stores/DoctorStore";
import { IMedication, IOptimizerMedication } from "../../../../../types";
import { CombinationItem } from "./CombinationItem";
import { VerticalSeparator } from "./VerticalSeparator";

const CombinationsContainer = styled.div`
    display: flex;
    background-color: #f9f9f9;
`;

const FixedCombinationItemSpacing = styled.div`
    padding: 30px 0px 20px 0px;
    word-break: break-all;
`;

const FixedCombinationItemContainer = styled.div`
    display: flex;
`;

const ScrollableList = styled.div`
    display: flex;
    overflow-y: hidden;
    flex-grow: 1;
    padding: 30px 0px 20px 0px;
`;

type IProps = {
    data: IOptimizerMedication[];
    showDetailInfo?: boolean;
    onClickSelect: (combination: IOptimizerMedication) => void;
};

export const Combinations = observer(({ data, showDetailInfo, onClickSelect }: IProps) => {
    const [hoveredItemIndex, setHoveredItemIndex] = React.useState<number>(-1);
    if (!doctorStore.currentMedication) {
        return null;
    }

    // calculate max enzymes per row
    const maxEnzymeRows = React.useMemo(
        () =>
            (doctorStore.currentMedication?.medication || []).map((substance, row) =>
                Math.max(
                    ...[
                        substance.interaction?.enzymes?.length ?? 0,
                        ...data.map(dataSet => dataSet.medication[row]?.interaction?.enzymes?.length ?? 0),
                    ],
                ),
            ),
        [data],
    );

    return (
        <CombinationsContainer>
            <FixedCombinationItemSpacing>
                <FixedCombinationItemContainer>
                    <CombinationItem
                        title={t("screen.doctor.auto_optimize.section.combination.current")}
                        combination={doctorStore.currentMedication}
                        showDetailInfo={showDetailInfo}
                        maxEnzymeRows={maxEnzymeRows}
                    />
                    <VerticalSeparator />
                </FixedCombinationItemContainer>
            </FixedCombinationItemSpacing>
            <ScrollableList>
                {data.map((combination, index) => {
                    return (
                        <React.Fragment key={`medication_${index}`}>
                            <div
                                onMouseEnter={() => setHoveredItemIndex(index)}
                                onMouseLeave={() => setHoveredItemIndex(-1)}
                            >
                                <CombinationItem
                                    title={`${t(
                                        "screen.doctor.auto_optimize.section.combination.replacement",
                                    )} ${index + 1}`}
                                    combination={combination}
                                    showDetailInfo={showDetailInfo}
                                    onClickSelect={onClickSelect}
                                    maxEnzymeRows={maxEnzymeRows}
                                    isHovered={index === hoveredItemIndex}
                                />
                            </div>
                            <VerticalSeparator
                                visible={
                                    data.length !== index + 1 ||
                                    (data.length > 0 && data.length === hoveredItemIndex + 1)
                                }
                            />
                        </React.Fragment>
                    );
                })}
            </ScrollableList>
        </CombinationsContainer>
    );
});
